import nxModule from 'nxModule';

import templateUrl from './official-receipt-booklet-list.template.html';
import './official-receipt-booklet-list.style.less'
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';
import {BreadcrumbsService} from 'components/common/breadcrumbs/breadcrumbs.service';
import AuthenticationService from '../../../../../react/authentication/AuthenticationService';
import {NxIFilterService} from 'components/technical/angular-filters';
import {ILocationService} from 'angular';
import {PageSettings} from 'components/common/selectable-list/selectable-list.component';
import {HttpService} from 'shared/utils/httpService';
import {NgTableParams} from 'ng-table';
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {Branch} from "management/BranchTypes";
import {PageResult} from "tools/HttpTypes";
import {Confirmation} from "shared/common/confirmation.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

export interface OfficialReceiptBooklet {
  id?: number;
  branchId: number;
  userId?: number;
  startSeries: number,
  endSeries: number;
}

class OfficialReceiptBookletList {
  private branch!: Branch;
  private baseLink!: string;
  private tableConfig!: NgTableParams<OfficialReceiptBooklet>;

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private http: HttpService,
              private $filter: NxIFilterService,
              private authentication: AuthenticationService,
              private breadcrumbs: BreadcrumbsService,
              private userCache: UserCache) {
  }

  async $onInit(): Promise<void> {
    setBreadcrumbs(this.breadcrumbs, 'official-receipt-booklet-list-label', this.branch.name);
    this.baseLink = `/admin/invoice-branches/${this.branch.id}`;

    const users: UserDetails[] = await this.userCache.withParam(true).toPromise();
    this.tableConfig = this.getTableConfig(users);
  }

  getTableConfig(users: UserDetails[]): NgTableParams<OfficialReceiptBooklet> {
    return new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<OfficialReceiptBooklet>): Promise<OfficialReceiptBooklet[]> => {
        const body = {
          branchId: this.branch.id,
          pageNo: params.page() - 1,
          pageSize: params.count()
        };
        const response = await this.http.post<PageResult<OfficialReceiptBooklet>>(
          '/management/official-receipt-booklet', body)
          .toPromise();
        params.total(response.totalCount);
        return response.result.map(r => {
          const user = users.find(user => user.id === r.userId);
          return {...r, userName: user?.effectiveName};
        });
      }
    });
  }

  async getBooklets(pageSettings: PageSettings): Promise<PageResult<OfficialReceiptBooklet>> {
    const params = {
      pageSize: pageSettings.pageSize,
      pageNo: pageSettings.pageNo,
      branchId: this.branch.id
    };

    return await this.http.get<PageResult<OfficialReceiptBooklet>>(`/management/official-receipt-booklets`, {params}).toPromise();
  }

  create(): void {
    this.$location.path(`${this.baseLink}/create`);
  }

  assign(id: number): void {
    this.$location.path(`${this.baseLink}/assign/${id}`);
  }

  getRowNo(index: number): number {
    const currentPage = this.tableConfig.page() - 1;
    const rowsPerPage = this.tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1;
  }
}

nxModule.component('officialReceiptBookletList', {
  templateUrl,
  bindings: {
    branch: '<'
  },
  controller: OfficialReceiptBookletList
});
