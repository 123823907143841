import systemPropertyService from 'system/systemPropertyService';
import nxModule from "nxModule";

export class RiskProfilingService {

  constructor(http) {
    this.http = http;
  }

  async shouldDisplayRiskProfilingByBranch(branchId) {
    if (!branchId) {
      return false;
    }

    const customerBranch = await this.http.get(`/management/branches/${branchId}`).toPromise();
    if (!customerBranch) {
      return false;
    }

    return await this.getDisplayRiskProfiling(customerBranch.code);
  }

  async getDisplayRiskProfiling(branchCode) {
    const isRiskProfilingEnabled = systemPropertyService.getProperty('RISK_PROFILING_ENABLED') === 'TRUE';
    const branchCodeStringValue = systemPropertyService.getProperty('RISK_PROFILING_SELECTED_BRANCH_CODES');
    const riskProfilingSelectedBranchCodes = branchCodeStringValue.split(',').map(o => o.trim());
    const isBranchFilterDisabled = riskProfilingSelectedBranchCodes[0] === '';
    const isBranchCodeIncluded = riskProfilingSelectedBranchCodes[0] !== ''
      && riskProfilingSelectedBranchCodes.includes(branchCode);

    return isRiskProfilingEnabled && (isBranchFilterDisabled || isBranchCodeIncluded);
  }
}

nxModule.service('riskProfilingService', RiskProfilingService);