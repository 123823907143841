import {
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikCashInput,
  NxFormikDatePicker,
  NxFormikInput,
  NxFormikSelect,
  NxFormikSubmitButton,
  NxLoader,
  NxPopup,
  NxRow,
  NxRowPosition, NxSelectOption,
  NxStack
} from '@nextbank/ui-components';
import useAxios from 'axios-hooks';
import CommandAccess from 'command/CommandAccess';
import {GuaranteeCustomerType, GuaranteeType, guaranteeTypes} from 'components/service/collateral';
import {Account, Hold} from 'components/service/product.types';
import {DepositDetails} from 'deposit/DepositType';
import {Dictionary} from 'dictionary/DictionaryType';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import BranchService from 'management/BranchService';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import NxFormikFileUpload from 'tools/file-upload/NxFormikFileUpload';
import {HttpError} from 'tools/HttpTypes';
import * as Yup from 'yup';

export interface CollateralInput {
  id?: number;
  customerId: number;
  securityId?: number;
  guaranteeCustomerType?: GuaranteeCustomerType;
  guaranteeType?: GuaranteeType;
  holdId?: number | null;
  name?: string;
  assetCode?: string;
  ownerName?: string;
  address?: string;
  description?: string;
  remarks?: string;
  appraisalValue?: number;
  holdOutAmount?: number;
  appraisalDate?: string;
  holdOutDate?: string;
  fileIds?: number[];
}

export interface Collateral {
  id: number;
  customerId: number;
  securityId: number;
  guaranteeCustomerType?: GuaranteeCustomerType;
  guaranteeType: GuaranteeType;
  holdId: number;
  holdProductNumber: string;
  name: string;
  assetCode?: string;
  ownerName?: string;
  address?: string;
  description?: string;
  remarks?: string;
  appraisalValue?: number;
  holdOutAmount?: number;
  appraisalDate?: string;
  holdOutDate?: string;
  fileIds?: number[];
}

const RequiredId = Yup.number()
  .required()
  .min(1);

const CollateralFormSchema: Yup.SchemaOf<CollateralInput> = Yup.object().shape({
  id: Yup.number().positive().optional(),
  customerId: RequiredId,
  securityId: RequiredId,
  guaranteeCustomerType: Yup.mixed().optional(),
  guaranteeType: Yup.mixed().required('Guarantee type must not be empty'),
  holdId: Yup.number().optional().min(1).nullable(),
  name: Yup.string().required('Collateral name must not be empty'),
  assetCode: Yup.string().optional(),
  ownerName: Yup.string().optional(),
  address: Yup.string().optional(),
  description: Yup.string()
    .typeError('Description must be a valid string')
    .optional(),
  remarks: Yup.string().optional(),
  appraisalValue: Yup.number()
    .positive('Appraisal value must be greater than 0')
    .typeError('Appraisal value must be greater than 0'),
  holdOutAmount: Yup.number()
    .positive('Hold out deposit amount must be greater than 0')
    .typeError('Hold out deposit amount must be greater than 0'),
  appraisalDate: Yup.string().typeError('Appraisal date format is invalid').optional(),
  holdOutDate: Yup.string().typeError('Hold out date format is invalid').optional(),
  fileIds: Yup.array()
    .min(1,'Input value is not a valid ID')
    .required()
});

export interface CollateralProps {
  collateral: CollateralInput,
  submitAction: (collateral: CollateralInput) => Promise<void>,
  confirmationMessage: string,
  actionName: string,
  commandAccessName: string
}

const branchService = new BranchService();
const CollateralForm = (props: CollateralProps): ReactElement => {

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
  const history = useHistory();
  const [{data: dictionaries, loading}] = useAxios<Dictionary[], HttpError>('/dictionaries?code=LOAN_SECURITY');
  const [branchSystemDate, setBranchSystemDate] = useState<string>();
  const {customerId} = useParams<{customerId: string}>();
  const [{data: accounts}] = useAxios<Account[]>(`/products/accounts/details?customerId=${customerId}&includeOwnership=true`);
  const [{data: deposits}] = useAxios<DepositDetails[]>(`/products/deposits?customerId=${customerId}&includeOwnership=true`);
  const [{ data: holdsData }] = useAxios(`/products/holds?customerIds=${encodeURIComponent(customerId)}&holdStatuses=ACTIVE&holdTypes=MANUAL`);
  const holds : Hold[] = holdsData?.result || [];

  const initialize = async (): Promise<void> => {
    const currentBranch = await branchService.readCurrentBranch();
    setBranchSystemDate(currentBranch.systemDate);
  };
  useEffect(() => {
    initialize();
  }, []);

  const accountMap = accounts ? new Map<number, string>(
    accounts.map(account => [account.id, account.productNumber])
  ) : new Map<number, string>();

  const depositMap = deposits ? new Map<number, string>(
    deposits.map(deposit => [deposit.id, deposit.productNumber])
  ) : new Map<number, string>();

  const productMap = new Map<number, string>([
    ...accountMap,
    ...depositMap
  ])

  const productHolds: NxSelectOption<number>[] = holds.map(hold => {
    const productNumber = productMap.get(hold.productId);
    return {
      label: productNumber ? `${productNumber} - ${hold.creationRemarks}` : hold.creationRemarks,
      value: hold.id,
    };
  });

  const loanSecurities = useMemo(() => {
    if(!dictionaries || dictionaries.length !== 1) {
      return [];
    }
    return dictionaries[0].entries
      .filter(e => e.code !== "CLN")
      .map(e => {
      const englishEntry = e.values.find(v => v.languageCode === 'en');
      return {
        label: englishEntry?.value,
        value: e.id
      }
    });
  }, [dictionaries]);

  const getLoanSecurity = (collateralTypeId: number | undefined): string => {
    if (!collateralTypeId) {
      return '';
    }
    const loanSecurity = loanSecurities?.find(d => d.value === collateralTypeId)?.label ?? '';
    if (['REM - Agricultural', 'REM - Commercial', 'REM - Residential'].includes(loanSecurity)) {
      return 'REM';
    } else if ("Other Collateral" === loanSecurity) {
      return 'OTR';
    } else if ("Chattel Mortgage" === loanSecurity) {
      return 'CM';
    } else if ("Hold Out Deposit / Non Risk Asset" === loanSecurity) {
      return 'HOD';
    }
    return '';
  };

  const guaranteeCustomerTypes : NxSelectOption<GuaranteeCustomerType>[] = [
    {
      label: 'Domestic',
      value: 'DOMESTIC'
    }, {
      label: 'Foreign',
      value: 'FOREIGN'
    }
  ];

  const guaranteeTypesOptions: NxSelectOption<GuaranteeType>[] = guaranteeTypes.map(option => ({
    label: option.label,
    value: option.value as GuaranteeType
  }));

  if(loading || !loanSecurities?.length) {
    return <NxPage><NxHeader>Collaterals</NxHeader><NxLoader/></NxPage>
  }

  const validateFields = (values: CollateralInput) => {
    const errors: { [key: string]: string } = {};
    if (getLoanSecurity(values.securityId) !== 'HOD') {
      if (!values.ownerName || values.ownerName === '') {
        errors.ownerName = 'Owner name must not be empty';
      }
      if (!values.appraisalValue) {
        errors.appraisalValue = 'Appraisal value must not be empty';
      }
    } else {
      if (!values.holdId) {
        errors.holdId = 'Hold product number must not be empty';
      }
      if (!values.holdOutAmount) {
        errors.holdOutAmount = 'Hold out deposit amount must not be empty';
      } else {
        const holdAmount = holds.find(h => h.id === values.holdId)?.amount ?? 0;
        if (values.holdOutAmount && values.holdOutAmount > holdAmount) {
          errors.holdOutAmount = 'Hold out deposit amount should be within the hold amount of ' + holdAmount.toFixed(2);
        }
      }
    }
    return errors;
  };

  return <>
      <NxFormik<CollateralInput>
        initialValues={{
          id: props.collateral.id,
          customerId: props.collateral.customerId,
          name: props.collateral.name,
          assetCode: props.collateral.assetCode ?? undefined,
          ownerName: props.collateral.ownerName ?? undefined,
          address: props.collateral.address ?? undefined,
          description: props.collateral.description ?? undefined,
          remarks: props.collateral.remarks ?? undefined,
          securityId: props.collateral.securityId,
          guaranteeCustomerType: props.collateral.guaranteeCustomerType ?? 'DOMESTIC',
          guaranteeType: props.collateral.guaranteeType,
          holdId: props.collateral.holdId,
          appraisalDate: props.collateral.appraisalDate ?? undefined,
          holdOutDate: props.collateral.holdOutDate ?? branchSystemDate,
          appraisalValue: props.collateral.appraisalValue ?? undefined,
          holdOutAmount: props.collateral.holdOutAmount ?? undefined,
          fileIds: props.collateral.fileIds
        }}
        validationSchema={CollateralFormSchema}
        validate={validateFields}
        onSubmit={(values : CollateralInput) : Promise<void> => props.submitAction(values)}
      >
        {({
            isValid,
            values,
            isSubmitting,
            submitForm,
            dirty, 
          }): ReactElement => {
          return <NxForm>
            <NxStack>
              <NxFormikSelect<number>
                name='securityId'
                label='Type'
                options={loanSecurities}
              />
              <NxFormikInput label='Name' name='name'/>
              <NxFormikSelect<GuaranteeCustomerType>
                name='guaranteeCustomerType'
                label='Guarantee customer type'
                options={guaranteeCustomerTypes}/>
              <NxFormikSelect<GuaranteeType>
                name='guaranteeType'
                label='Guarantee type'
                options={guaranteeTypesOptions}/>
              { getLoanSecurity(values.securityId) === 'HOD' && (
                <NxFormikSelect<number>
                  name='holdId'
                  label='Hold out deposit product number'
                  options={productHolds}
                />
              )}
              { getLoanSecurity(values.securityId) !== 'HOD' && <NxFormikInput label='Asset code' name='assetCode' maxLength={20}/> }
              { getLoanSecurity(values.securityId) !== 'HOD' && <NxFormikInput label='Collateral owner name' name='ownerName' maxLength={220}/> }
              { getLoanSecurity(values.securityId) === 'REM' && <NxFormikInput label='Collateral address' name='address' maxLength={200}/> }
              <NxFormikInput label='Description' name='description'/>
              <NxFormikInput label='Remarks' name='remarks'/>
              { getLoanSecurity(values.securityId) !== 'HOD' && <NxFormikDatePicker label={'Appraisal date'} name='appraisalDate'/> }
              { getLoanSecurity(values.securityId) === 'HOD' && <NxFormikDatePicker label={'Hold out date'} name='holdOutDate'/> }
              { getLoanSecurity(values.securityId) !== 'HOD' && <NxFormikCashInput label={'Appraisal value'} name='appraisalValue'/> }
              { getLoanSecurity(values.securityId) === 'HOD' && <NxFormikCashInput label={'Hold out deposit amount'} name='holdOutAmount'/> }

              <NxFormikFileUpload label={'Collateral'}
                                  name={'fileIds'}
                                  allowDuplicates={false}/>

              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={(): void => setShowCancelPopup(true)}
                >
                  Cancel
                </NxButton>
                <CommandAccess commandName={props.commandAccessName}>
                  <NxButton onClick={(): void => setShowPopup(true)}
                            disabled={!isValid || isSubmitting || !dirty}>
                    {props.actionName}
                  </NxButton>
                </CommandAccess>
              </NxRow>
            </NxStack>

            <NxPopup header='Confirm'
                     open={showPopup && isValid}
                     description={props.confirmationMessage}>
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={() : void => setShowPopup(false)}>
                  No
                </NxButton>
                <NxFormikSubmitButton
                  variant={NxButtonVariant.SAVE}
                  onClick={async (): Promise<void> => {
                    setShowPopup(false);
                    let collateralType = getLoanSecurity(values.securityId);
                    if (collateralType !== 'REM') {
                      values.address = undefined;
                    }
                    if (collateralType === 'HOD') {
                      values.assetCode = undefined;
                      values.ownerName = undefined;
                      values.appraisalDate = undefined;
                      values.appraisalValue = undefined;
                    } else {
                      values.holdId = undefined;
                      values.holdOutDate = undefined;
                      values.holdOutAmount = undefined;
                    }
                    submitForm();
                  }}>
                  Yes
                </NxFormikSubmitButton>
              </NxRow>
            </NxPopup>

            <NxPopup header='Confirm'
                     open={showCancelPopup}
                     description='Do you want to cancel? Cancelling will discard all changes'>
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={() : void => setShowCancelPopup(false)}>
                  No
                </NxButton>
                <NxButton
                  variant={NxButtonVariant.CONTAINED}
                  onClick={(): void => {
                    setShowCancelPopup(false);
                    history.goBack();
                  }}>
                  Yes
                </NxButton>
              </NxRow>
            </NxPopup>
          </NxForm>
        }
      }
    </NxFormik>
  </>;
}

export default CollateralForm;
