import nxModule from 'nxModule';

import _ from 'lodash';
import {IController, ILocationService} from "angular";
import templateUrl from './transactions-category-details.template.html';
import './transactions-category-details.style.less';
import {AccountWithLabel, addAccountLabels} from "../../general-ledger/common/gl.utils";
import systemPropertyService from "../../../../react/system/systemPropertyService";
import {Branch} from "management/BranchTypes";
import {NxIFilterService} from "components/technical/angular-filters";
import {CommandService} from "shared/utils/command/command.types";
import {BranchService} from "components/service/branch.service";
import Authentication from "shared/utils/authentication";
import GlMappingsService from "components/administration/gl-mappings/gl-mappings.service";
import {ActionCategoryCache} from 'components/service/action/action-category.cache.types';

interface CreateActionCategoryInput {
  name: string;
  actionType: string;
  targetBranchIds: number[] | null;
  ledgerAccountFullCodes: string[];
  allowedBranchIds: number[] | null;
}

interface Category extends CreateActionCategoryInput {
  id: number;
}

class TransactionsCategoryDetails implements IController {
  readonly availableActionTypes: {name:string, value: string}[] = [];
  name: string = '';
  actionTypes: string[] = [];
  supportsLedger: boolean = false;
  accounts: {fullCode: string | null}[] = [];
  ledgerAccounts!: AccountWithLabel[];
  allBranches!: Branch[];
  selectedBranchIds: number[] | null = [];
  allowedBranchIds: number[] | null = [];

  readonly editMode!: boolean;
  description!: string;
  category!: Category;

  constructor(private readonly glMappingsService: GlMappingsService,
              private readonly actionCategoryCache: ActionCategoryCache,
              private readonly command: CommandService,
              private readonly $location: ILocationService,
              private readonly branchService: BranchService,
              private readonly authentication: Authentication,
              private readonly $filter: NxIFilterService) {

    this.availableActionTypes = [
      'CASH_IN',
      'DISTRIBUTED_CASH_IN',
      'CHECK_IN',
      'BATCH_CHECK_IN',
      'CASH_OUT',
      'DISTRIBUTED_CASH_OUT',
      'CHECK_OUT',
      'OFFICIAL_RECEIPT_IN_CASH',
      'DISTRIBUTED_OFFICIAL_RECEIPT_IN_CASH',
      'OFFICIAL_RECEIPT_IN_CHECK',
      'DISTRIBUTED_OFFICIAL_RECEIPT_IN_CHECK',
      'CREATE_INTERBRANCH_CASHIER_CHECK',
      'ACCOUNT_BATCH_MEMO_CREDIT',
      'ACCOUNT_BATCH_MEMO_DEBIT',
      'LOAN_BATCH_GL_PAYMENT',
      'LOAN_GL_PAYMENT',
      'LOAN_GL_PAYMENT_REVERT',
      'CREDIT_DEPOSIT_BY_MEMO',
      'DEBIT_DEPOSIT_BY_MEMO',
      'DEPOSIT_PLACEMENT_BY_MEMO',
      'CREDIT_ACCOUNT_BY_MEMO',
      'DEBIT_ACCOUNT_BY_MEMO',
      'MANUAL_TICKET',
      'MANUAL_INTERBRANCH_TICKET_SOURCE',
      'MANUAL_INTERBRANCH_TICKET_TARGET',
      'CASHIER_CHECK_CREATION',
      'CREATE_CASHIER_CHECK_TAX',
      'ATM_CASH_WITHDRAWAL',
    ].map(actionType => ({
      name: $filter('replaceWithInvoice')($filter('translate')(actionType, 'ACTION_TYPE')),
      value: actionType
    }));
  }

  async $onInit(): Promise<void> {
    const [ledgerAccounts, branches] = await Promise.all([
      this.glMappingsService.accounts.toPromise(),
      this.branchService.toPromise()
    ]);

    this.supportsLedger = systemPropertyService.getProperty('LEDGER_SUPPORT') === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);
    // Add first GL account (min 1 is required)
    this.accounts.push({fullCode: null});

    this.allBranches = branches;

    if (this.editMode) {
      this.name = this.category.name;
      this.actionTypes = [this.category.actionType];
      this.selectedBranchIds = this.isCategoryDefinedForAllTargetBranches() ? this.allBranches.map(b => b.id) : this.category.targetBranchIds;
      this.accounts = this.category.ledgerAccountFullCodes.map(val => ({fullCode: val}));
      this.allowedBranchIds =  _.isEmpty(this.category.allowedBranchIds) ? this.allBranches.map(b => b.id) : this.category.allowedBranchIds;
    }
  }

  private isCategoryDefinedForAllTargetBranches(): boolean {
    return this.isTargetBranchLimited(this.category.actionType) && _.isEmpty(this.category.targetBranchIds);
  }

  addNewAccount(): void {
    this.accounts.push({fullCode: null});
  }

  removeAccount(index: number): void {
    this.accounts = this.accounts.filter((_acc, i) => i != index);
  }

  async save(): Promise<void> {
    const accountFullCodes = this.accounts.map(acc => acc.fullCode ?? '');
    for (const actionType of this.actionTypes) {
      const actionCategory: CreateActionCategoryInput = {
        name: this.name,
        actionType,
        targetBranchIds: this.getSelectedTargetBranchIds(actionType),
        ledgerAccountFullCodes: accountFullCodes,
        allowedBranchIds: _.isEqual(this.allowedBranchIds, this.allBranches.map(b => b.id)) ? null : this.allowedBranchIds
      };

      if (this.editMode) {
        await this.command.execute('UpdateActionCategory', {
          ...actionCategory,
          id: this.category.id
        }, {nxLoaderText: 'Updating category'}).toPromise();
      } else {
        await this.command.execute('CreateActionCategory', actionCategory, {nxLoaderText: 'Creating category'}).toPromise();
      }
    }

    this.actionCategoryCache.refetch();
    this.redirectBack();
  }

  private getSelectedTargetBranchIds(actionType: string): number[] | null {
    if (!this.isTargetBranchLimited(actionType) || this.isSelectAllBranchesPicked()) {
      return null;
    }
    return this.selectedBranchIds;
  }

  private isSelectAllBranchesPicked(): boolean {
    return _.isEqual(this.selectedBranchIds, this.allBranches.map(b => b.id));
  }

  isTargetBranchLimitAvailable(): boolean {
    return this.actionTypes && this.actionTypes.some(actionType => this.isTargetBranchLimited(actionType));
  }

  isTargetBranchLimited(actionType: string): boolean {
    return ['DISTRIBUTED_CASH_IN', 'DISTRIBUTED_CASH_OUT'].includes(actionType);
  }

  redirectBack(): void {
    this.$location.path('/admin/general-ledger');
  }

  cancel(): void {
    this.redirectBack();
  }

  atLeastOneAccountSelected(): boolean {
    return this.accounts.length > 0;
  }
}

nxModule.component('transactionsCategoryDetails', {
  templateUrl,
  bindings: {
    description: '@',
    editMode: '<',
    category: '<'
  },
  controller: TransactionsCategoryDetails
});
