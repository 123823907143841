import nxModule from 'nxModule';

import templateUrl from './risk-assessment.template.html';
import './risk-assessment.style.less';

class RiskAssessmentView {
  constructor(http, confirmation, $location, dict, command) {
    this.http = http;
    this.confirmation = confirmation;
    this.$location = $location;
    this.dict = dict;
    this.command = command;
  }

  async $onInit() {
    const entries = await this.http.get('/management/risk/assessment').toPromise();
    this.sortedEntries = entries.sort((a, b) => a.minPoints - b.minPoints) // 0 min points at the top
      .map(e => ({...e, isValid: true}));

    this.dict.onLoadingComplete(() => {
      this.riskDictionaryEntries = this.dict['CUSTOMER_RISK_LEVEL'];
    });
  }

  validateRiskWeight() {
    const length = this.sortedEntries.length;

    const duplicatedWeight = this.markAsInvalidDuplicateWeights();
    if (duplicatedWeight) {
      return;
    }

    // mark invalid if weights are not low < normal < high
    for (let i = 0; i < length; i++) {
      if (i < length - 1 && this.sortedEntries[i].weight > this.sortedEntries[i+1].weight) {
        this.sortedEntries[i].isValid = false;
        this.sortedEntries[i+1].isValid = false;
        if (i === 0) {
          i++;
        }
      } else if (i === length - 1 && this.sortedEntries[i-1].weight > this.sortedEntries[i].weight) {
        this.sortedEntries[i-1].isValid = false;
        this.sortedEntries[i].isValid = false;
      } else {
        this.sortedEntries[i].isValid = true;
      }
    }
  }

  markAsInvalidDuplicateWeights() {
    const length = this.sortedEntries.length;
    const occurrenceMap = new Map();
    this.sortedEntries.forEach((item) => {
      const value = item.weight;
      occurrenceMap.set(value, (occurrenceMap.get(value) || 0) + 1);
      item.isValid = true;
    });

    let duplicatedWeight = null;
    occurrenceMap.forEach((count, value) => {
      if (count > 1) {
        duplicatedWeight = value;
      }
    });

    for (let i = 0; i < length; i++) {
      if (this.sortedEntries[i].weight === duplicatedWeight) {
        this.sortedEntries[i].isValid = false;
      }
    }
    return duplicatedWeight;
  }

  isMinPointsGtPreviousMaxPoints(index) {
    const current = this.sortedEntries[index]
    if (index === 0) {
      return current.minPoints === 0;
    }

    const previous = this.sortedEntries[index - 1];
    return current.minPoints > previous.maxPoints;
  }

  isMaxPointsGteMinPoints(index) {
    const current = this.sortedEntries[index]
    return current.maxPoints >= current.minPoints;
  }

  areAllEntriesValid() {
    if (!this.sortedEntries) {
      return false;
    }

    for (let i = 0; i < this.sortedEntries.length; i++) {
      if (this.sortedEntries[i].isValid === false) {
        return false;
      }

      if (!this.isMinPointsGtPreviousMaxPoints(i)) {
        return false;
      }

      if (!this.isMaxPointsGteMinPoints(i)) {
        return false;
      }
    }

    return true;
  }

  async save() {
    const confirmation = await this.confirmation('Do you want to update it?');
    if (!confirmation) {
      return;
    }

    await this.command.execute('UpdateRiskAssessments', {entries: this.sortedEntries}).toPromise();
    this.goBack();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }
}

nxModule.component('riskAssessmentView', {
  templateUrl,
  controller: RiskAssessmentView
});
