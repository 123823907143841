import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './batch-pdc-payment.template.html';
import './batch-pdc-payment.style.less';
import {IController} from 'angular';
import {BranchService} from '../../service/branch.service';
import {Column, PageSettings, Record, SelectableListAPI} from '../../common/selectable-list/selectable-list.component';
import {LoanPDCDetails, LoanPDCStatus} from '../../service/loan-pdc.types';
import {Columns} from '../../common/dynamic-list/dynamic-list.component';
import {NxIFilterService} from "components/technical/angular-filters";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {Branch} from "management/BranchTypes";
import {PageResult} from "tools/HttpTypes";
import systemPropertyService from "system/systemPropertyService";
import {OfficialReceipt} from "components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types";
import Notification from "shared/utils/notification";
import {ModalApi} from "components/technical/modal/modal.component";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import {LoanCache} from "components/service/loan.cache";
import {CustomerCache} from 'components/service/customer.cache.types';

interface PdcRow extends Record {
  loanName: string;
  loanNumber: string;
  micr: string;
  status: string;
  amountFormatted: string;
  type: string;
  dueDate: string;
  officialReceipt: string;
}

interface BatchPdcPaymentRequest {
  pdcs: PDC[];
}

interface PDC {
  id: number;
  officialReceipt: string;
}

interface SingleResult {
  micr: string;
  amount: number;
  amountFormatted: string;
  officialReceipt: string;
  operationId: number;
  errors?: string[];
}

interface Output {
  results: SingleResult[];
}

class BatchPdcPayment implements IController {
  // FILTERING
  availableStatuses!: {label: string, value: LoanPDCStatus}[];
  filter!: { dateRange: { from?: string, to?: string }, status: LoanPDCStatus[] };

  // SELECTABLE LIST
  columns: Column<PdcRow>[] = [
    new Column<PdcRow>('Loan name', 'loanName'),
    new Column<PdcRow>('Loan Number', 'loanNumber'),
    new Column<PdcRow>('MICR', 'micr'),
    new Column<PdcRow>('Status', 'status'),
    new Column<PdcRow>('Amount', 'amountFormatted'),
    new Column<PdcRow>('Type', 'type'),
    new Column<PdcRow>('Due Date', 'dueDate'),
  ];
  private selectedPdcs!: PdcRow[];
  tableAPI!: SelectableListAPI;

  // REQUEST
  officialReceipt?: string;

  // PAYMENT RESULTS
  readonly validPaymentColumns: Columns = [
    { title: 'No' },
    { title: 'MICR', field: 'micr' },
    { title: 'Amount', field: 'amountFormatted' },
    { title: 'Invoice', field: 'officialReceipt' },
    { title: 'Transaction no', field: 'operationId' }
  ];
  validPaymentData?: SingleResult[];
  readonly invalidPaymentColumns: Columns = [
    { title: 'No' },
    { title: 'MICR', field: 'micr' },
    { title: 'Amount', field: 'amountFormatted' },
    { title: 'Errors', field: 'errorsFormatted' }
  ];
  invalidPaymentData?: SingleResult[];

  private isMultipleOr = false;
  private autoOfficialReceiptEnabled = false;
  private batchPDCPaymentModalApi!: ModalApi;
  private modalSelectedPdcs: PdcRow[] = [];

  constructor(
    private $filter: NxIFilterService,
    private authentication: Authentication,
    private branchService: BranchService,
    private http: HttpService,
    private command: CommandService,
    private confirmation: Confirmation,
    private customerCache: CustomerCache,
    private loanCache: LoanCache,
    private notification: Notification
  ) {}

  async $onInit(): Promise<void> {
    this.autoOfficialReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    this.initAvailableStatuses();
    this.initDefaultFilters();
    await this.fetchFirstUnusedOfficialReceipt();
  }

  async showBatchPDCPaymentModal() : Promise<void> {
    if(this.autoOfficialReceiptEnabled) {
      const updatedPDCRows = await this.getSelectedPdcsAndEnrichWithAutomatedOr();

      if(!updatedPDCRows){
        return;
      }

      this.modalSelectedPdcs = updatedPDCRows;
    }else{
      if(this.officialReceipt){
        this.modalSelectedPdcs = this.getSelectedPdcsAndEnrichWithConsecutiveOr();
      }
    }
    await this.batchPDCPaymentModalApi.show();
  }

  onBatchPDCPaymentModalApiReady = ({api}: {api: ModalApi}) => {
    this.batchPDCPaymentModalApi = api;
  };

  async fetchFirstUnusedOfficialReceipt(): Promise<void> {
    if (!this.autoOfficialReceiptEnabled) {
      return;
    }

    const userId = this.authentication.context.id;
    const firstUnusedOr = await this.http.get<OfficialReceipt>(`/official-receipt/next-available?userId=${userId}`).toPromise();
    this.officialReceipt = firstUnusedOr.receiptNumber;
  }

  private initAvailableStatuses(): void {
    const allowedStatuses: LoanPDCStatus[] = [LoanPDCStatus.DUE, LoanPDCStatus.OVERDUE, LoanPDCStatus.POSTPONED, LoanPDCStatus.REJECTED];
    this.availableStatuses = allowedStatuses.map(status => ({label: this.$filter('prettyEnum')(status), value: status}));
  }

  private async initDefaultFilters(): Promise<void> {
    const branches = await this.branchService.toPromise();
    const defaultBranch: Branch | undefined = branches.find(branch => branch.id === this.authentication.context.branchId);
    const systemDate: string | undefined = defaultBranch?.systemDate;
    this.filter = {
      dateRange: {
        from: systemDate,
        to: systemDate
      },
      status: [LoanPDCStatus.DUE, LoanPDCStatus.OVERDUE, LoanPDCStatus.POSTPONED]
    };
  }

  onFilterBtnClicked(): void {
    this.tableAPI?.reset();
  }

  onSelectableListApiReady(tableAPI: SelectableListAPI): void {
    this.tableAPI = tableAPI;
  }

  async getPdcOnCurrentPage(pageSettings: PageSettings): Promise<PageResult<Omit<PdcRow, 'officialReceipt'> & {officialReceipt?: string}>> {
    const params = {
      pageSize: pageSettings.pageSize,
      pageNo: pageSettings.pageNo,
      branchId: this.authentication.context.branchId,
      status: this.filter.status,
      dueDateFrom: this.$filter('nxDate')(this.filter.dateRange.from),
      dueDateTo: this.$filter('nxDate')(this.filter.dateRange.to)
    };
    const pageResult = await this.http.get<PageResult<LoanPDCDetails>>('/products/loans/post-dated-checks', { params }).toPromise();
    const rows: (Omit<PdcRow, 'officialReceipt'> & {officialReceipt?: string})[] = pageResult.result.map((pdcDetails: LoanPDCDetails) => {
      return {
        id: pdcDetails.id,
        loanName: pdcDetails.productName,
        loanNumber: pdcDetails.productNumber,
        micr: pdcDetails.micrNumber,
        status: this.$filter('prettyEnum')(pdcDetails.status),
        amountFormatted: this.$filter('nxCurrency')(pdcDetails.amount),
        type: this.$filter('prettyEnum')(pdcDetails.type),
        dueDate: this.formatDueDate(pdcDetails)
      }
    });

    return {
      ...pageResult,
      result: rows
    };
  }

  private formatDueDate(pdcDetails: LoanPDCDetails): string {
    if (pdcDetails.postponedToDate) {
      return `${ this.$filter('prettyDate')(pdcDetails.postponedToDate) } (orig. ${ this.$filter('prettyDate')(pdcDetails.amortizationDueDate) })`
    } else {
      return this.$filter('prettyDate')(pdcDetails.amortizationDueDate);
    }
  }

  onPdcSelected(selectedPdcs: PdcRow[]): void {
    this.selectedPdcs = selectedPdcs;
  }

  isAnyPdcSelected(): boolean {
    return this.selectedPdcs && this.selectedPdcs.length > 0;
  }

  isEmpty(array: any[]): boolean {
    return _.isEmpty(array);
  }

  cancelBatchPDCModal(): void{
    this.batchPDCPaymentModalApi.onCancel();
    this.modalSelectedPdcs = [];
  }

  async confirmBatchPDCModal(): Promise<void> {
    this.batchPDCPaymentModalApi.onOk();
    await this.postPDCPayment()
      .finally(() => this.modalSelectedPdcs = []);
  }

  getSelectedPdcsAndEnrichWithConsecutiveOr(): PdcRow[] {
    return this.selectedPdcs.map((pdc, pdcIndex) => ({...pdc, officialReceipt: this.officialReceipt+'-'+(pdcIndex+1)}));
  }

  getSelectedPdcs(): PdcRow[] {
    if(this.modalSelectedPdcs.length > 0){
      return this.modalSelectedPdcs;
    } else {
      return this.getSelectedPdcsAndEnrichWithConsecutiveOr();
    }
  }

  async getSelectedPdcsAndEnrichWithAutomatedOr(): Promise<PdcRow[] | undefined> {
    const userId = this.authentication.context.id;
    const officialReceipts: string[] = await this.http.get<string[]>(`/official-receipt/next-n-available?userId=${userId}&count=${this.selectedPdcs.length}`).toPromise();
    if(officialReceipts.length < this.selectedPdcs.length){
      this.notification.show("Error", "User has reached the max number of official receipts");
      return;
    }
    return this.selectedPdcs.map((pdc, pdcIndex) => ({...pdc, officialReceipt: officialReceipts[pdcIndex]}));
  }

  async createBatchPdcPaymentRequest(): Promise<BatchPdcPaymentRequest | undefined>{
    if(this.autoOfficialReceiptEnabled){
      const pdcRows: PdcRow[] | undefined = await this.getSelectedPdcsAndEnrichWithAutomatedOr();

      if(!pdcRows){
        return;
      }

      return this.mapBatchPdcPaymentRequest(pdcRows);
    }

    const pdcsManualOr : PdcRow[] = this.getSelectedPdcs();
    return this.mapBatchPdcPaymentRequest(pdcsManualOr);
  }

  mapBatchPdcPaymentRequest(pdcRows: PdcRow[]): BatchPdcPaymentRequest {
    return {
      pdcs: pdcRows.map(pdc => {
        return {
          id: pdc.id,
          officialReceipt: pdc.officialReceipt
        }
      })
    }
  }

  async onPostClicked(): Promise<void> {
    this.validPaymentData = this.invalidPaymentData = [];

    const proceed: boolean = await this.confirmation(`Are you sure you want to post payment with ${this.selectedPdcs?.length} post dated checks?`);
    if (!proceed) {
      return;
    }

    await this.postPDCPayment();
  }

  async postPDCPayment(): Promise<void> {
    const request: BatchPdcPaymentRequest | undefined = await this.createBatchPdcPaymentRequest();

    if(!request) {
      return;
    }

    const { output, approvalRequired } = await this.command.execute<unknown, Output>('PayLoanByPDCBatch', request).toPromise();
    if (approvalRequired) {
      return;
    }

    this.tableAPI?.reset();
    this.officialReceipt = undefined;

    const [validPayments, invalidPayments] = _.partition(output.results, res => _.isEmpty(res.errors));
    this.validPaymentData = validPayments.map(result => ({...result, amountFormatted: this.$filter('nxCurrency')(result.amount)}));
    this.invalidPaymentData = invalidPayments.map(result => ({...result, amountFormatted: this.$filter('nxCurrency')(result.amount), errorsFormatted: result.errors?.join(', ')}));

    await this.fetchFirstUnusedOfficialReceipt();

    await Promise.all([
      this.customerCache.refetch(),
      this.loanCache.refetch(),
    ])
  }
}

nxModule.component('batchPdcPayment', {
  templateUrl: templateUrl,
  controller: BatchPdcPayment
});
