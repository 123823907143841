import nxModule from 'nxModule';
import {PaymentMethod, paymentMethods} from "constants/paymentMethods";
import {CsvSeparator, csvSeparators} from "constants/csvSeparators";
import {LoanBatchUploadFileType} from "constants/loanBatchFileTypes";

import templateUrl from './batch-loan-payments-upload.template.html';
import {NxIFilterService} from "../../technical/angular-filters";
import {Columns} from "../../common/dynamic-list/dynamic-list.component";

import './batch-loan-payments-upload.style.less';
import GlMappingsService, {LedgerAccountGroup} from "../../administration/gl-mappings/gl-mappings.service";
import {AccountWithLabel, addAccountLabels} from "../../general-ledger/common/gl.utils";
import {ActionCategory} from "../../administration/transactions/action-category.types";
import {Unit} from "../../general-ledger/common/gl-category-input.component";
import systemPropertyService from "../../../../react/system/systemPropertyService";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {OfficialReceipt} from "components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

type PayLoanByBatchInput = {
  paymentMethod: string,
  categoryId: number,
  officialReceipt: string,
  accountNumber: string,
  remarks: string,
  attachedFileId: number,
  csvSeparator: string,
  fileType: string,
  valueDate?: Date,
  skipSimulation: boolean
}

interface PayLoanByBatchOutput {
  payments: Payment[];
  formatErrors: FormatErrors;
}

interface PayLoanByBatchView extends PayLoanByBatchOutput {
  perRowFormatErrors: RowFormatError[],
  successfulColumns: Columns,
  perRowSuccessfulPayments: Payment[],
  businessErrorColumns: Columns,
  perRowBusinessErrors: PaymentError[]
}

interface RowFormatError {
  lineNo: number ;
  errors: string;
}

interface FormatErrors {
  [key: number]: string[];
}

interface Payment {
  row: Row;
  officialReceipt?: string;
  errors: string[];
}

interface PaymentError extends Payment {
  errorText: string
}

interface Row {
  lineNo: number;
  productNumber: string;
  amount: number;
  amountFormatted?: string;
  principalAmount: number;
  principalAmountFormatted?: string;
  interestAmount: number;
  interestAmountFormatted?: string;
  penaltyAmount: number;
  penaltyAmountFormatted?: string;
  pastDueInterestAmount: number;
  pastDueInterestAmountFormatted?: string;
  cbuChargeAmount: number;
  cbuChargeAmountFormatted?: string;
  pfChargeAmount: number;
  pfChargeAmountFormatted?: string;
  tpChargeAmount: number;
  tpChargeAmountFormatted?: string;
  customFeesAmount: number;
  customFeesAmountFormatted?: string;
}


class BatchLoanPaymentsUpload {
  private payment: Partial<PayLoanByBatchInput>;
  private paymentMethods: ReadonlyArray<PaymentMethod>;
  private csvSeparators: ReadonlyArray<CsvSeparator>;
  private fileTypes: ReadonlyArray<LoanBatchUploadFileType>;
  private simulation: PayLoanByBatchView | null;
  private templatesWithLabels!: AccountWithLabel[];
  private valueDateEnabled!: boolean;
  private autoOfficialReceiptEnabled!: boolean;
  private officialReceiptStart?: string;
  private readonly defaultFileType: LoanBatchUploadFileType = LoanBatchUploadFileType.NEXTBANK;
  skipSimulation = false;


  private readonly formatErrorColumns: Columns = [
    { title: 'Line No', field: 'lineNo'},
    { title: 'Errors', field: 'errors'}
  ];

  private readonly successfulColumns: Columns = [
    { title: 'Line No', field: ['row', 'lineNo']},
    { title: 'Invoice', field: 'officialReceipt'},
    { title: 'Product Number', field: ['row', 'productNumber']},
  ];

  private readonly amountColumns: Columns = [{ title: 'Amount', field: ['row', 'amountFormatted']}];

  private readonly successfulCustomPaymentColumns: Columns = [
    { title: 'Principal Amount', field: ['row', 'principalAmount']},
    { title: 'Interest Amount', field: ['row', 'interestAmount']},
    { title: 'Penalty Amount', field: ['row', 'penaltyAmount']},
    { title: 'Past Due Interest Amount', field: ['row', 'pastDueInterestAmount']},
    { title: 'CBU Charge Amount', field: ['row', 'cbuChargeAmount']},
    { title: 'PF Charge Amount', field: ['row', 'pfChargeAmount']},
    { title: 'TP Charge Amount', field: ['row', 'tpChargeAmount']},
    { title: 'Custom Fees Amount', field: ['row', 'customFeesAmount']},
  ];

  private readonly businessErrorColumns: Columns = [
    { title: 'Errors', field: 'errorText'}
  ];

  category?: ActionCategory;
  categoryUnits: Unit[] = [];

  constructor(
    private command: CommandService,
    private popup: Popup,
    private confirmationTemplate: ConfirmationTemplate,
    private $filter: NxIFilterService,
    private $route: NxRouteService,
    private glMappingsService: GlMappingsService,
    private http: HttpService,
    private authentication: Authentication,
    private customerCache: CustomerCache
  ) {
    this.paymentMethods = paymentMethods;
    this.csvSeparators = csvSeparators;
    this.fileTypes = LoanBatchUploadFileType.FILE_TYPES;
    this.payment = {
    };

    this.simulation = null;
  }

  async $onInit(): Promise<void> {
    const accountTemplates = await this.glMappingsService.accounts.toPromise();
    const paymentValidTemplates = accountTemplates.filter(template =>
      [LedgerAccountGroup.ASSET, LedgerAccountGroup.LIABILITY].includes(template.accountGroup));

    this.templatesWithLabels = addAccountLabels(paymentValidTemplates);
    this.payment.fileType = this.payment.fileType || this.defaultFileType.value;
    this.valueDateEnabled = systemPropertyService.getProperty('LOAN_PAYMENT_VALUE_DATE_ENABLED') === 'TRUE';
    this.autoOfficialReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    await this.fetchFirstUnusedOfficialReceipt();
  }

  async fetchFirstUnusedOfficialReceipt(): Promise<void> {
    if (!this.autoOfficialReceiptEnabled) {
      return;
    }

    const userId = this.authentication.context.id;
    const firstUnusedOr = <OfficialReceipt> await this.http.get(`/official-receipt/next-available?userId=${userId}`).toPromise();
    this.officialReceiptStart = firstUnusedOr.receiptNumber;
  }

  paymentMethodChanged() {
    this.category = undefined;
    this.payment.categoryId = undefined;
    this.categoryChanged();
  }

  async resetForm(): Promise<void> {
    this.clearSimulation();
    this.payment = {};
    this.skipSimulation = false;
    await this.fetchFirstUnusedOfficialReceipt();
  }

  downloadSampleFile(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const type: LoanBatchUploadFileType = LoanBatchUploadFileType[this.payment.fileType];
    if(!LoanBatchUploadFileType) {
      throw new Error(`Unknown file type ${this.payment.fileType}`);
    }

    const sampleFileUrl = window.URL.createObjectURL(type.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = type.sampleFilename;
    a.click();
  }

  clearSimulation(): void {
    this.simulation = null;
  }

  requiresSeparator(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.payment.fileType && LoanBatchUploadFileType[this.payment.fileType].useSeparator;
  }

  async simulatePayment(): Promise<void> {
    const { output } = await this.command.execute<unknown, PayLoanByBatchOutput>('SimulatePayLoanByBatch', this.payment).toPromise();

    let fileTypeSuccessfulColumns: Columns = this.successfulColumns;

    if(this.payment.fileType == LoanBatchUploadFileType.NEXTBANK_CUSTOM_PAYMENT.value){
      fileTypeSuccessfulColumns = [...fileTypeSuccessfulColumns, ...this.successfulCustomPaymentColumns];
    }else{
      fileTypeSuccessfulColumns = [...fileTypeSuccessfulColumns, ...this.amountColumns];
    }
    const fileTypeBusinessErrorColumns: Columns = [...fileTypeSuccessfulColumns, ...this.businessErrorColumns];

    this.simulation = {
      perRowFormatErrors: this.prepareFormatErrors(output),
      perRowBusinessErrors: this.preparePerRowBusinessErrors(output),
      businessErrorColumns: fileTypeBusinessErrorColumns,
      perRowSuccessfulPayments: this.prepareSuccessfulPayments(output),
      successfulColumns: fileTypeSuccessfulColumns,
      ...output
    };
  }

  private prepareSuccessfulPayments(output: PayLoanByBatchOutput): Payment[] {
    return output.payments
        .filter(payment => payment.errors.length === 0)
        .map(payment => ({
          ...payment,
          row: this.prepareRow(payment.row),
        }));
  }

  private preparePerRowBusinessErrors(output: PayLoanByBatchOutput): PaymentError[] {
    return output.payments
        .filter(payment => payment.errors.length > 0)
        .map(payment => ({
          errorText: payment.errors.join(', '),
          ...payment,
          row: this.prepareRow(payment.row),
        }));
  }

  // format amount to proper currency
  private prepareRow(row: Row): Row {
    return {
      amountFormatted: this.$filter('nxCurrency')(row.amount),
      principalAmountFormatted: this.$filter('nxCurrency')(row.principalAmount),
      interestAmountFormatted: this.$filter('nxCurrency')(row.interestAmount),
      penaltyAmountFormatted: this.$filter('nxCurrency')(row.penaltyAmount),
      pastDueInterestAmountFormatted: this.$filter('nxCurrency')(row.pastDueInterestAmount),
      cbuChargeAmountFormatted: this.$filter('nxCurrency')(row.cbuChargeAmount),
      pfChargeAmountFormatted: this.$filter('nxCurrency')(row.pfChargeAmount),
      tpChargeAmountFormatted: this.$filter('nxCurrency')(row.tpChargeAmount),
      customFeesAmountFormatted: this.$filter('nxCurrency')(row.customFeesAmount),
      ...row,
    };
  }

  private prepareFormatErrors(output: PayLoanByBatchOutput): RowFormatError[] {
    return Object.keys(output.formatErrors) //  lineNo -> listOf errors
        .sort((a, b) => parseInt(a) - parseInt(b)) // sort by line number
        .map((rowNumber: string): RowFormatError => ({
          lineNo: parseInt(rowNumber),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors: output.formatErrors[rowNumber].join(', ')
        }));
  }

  async submitPayment(): Promise<void> {
    const response = await this.command.execute<unknown, PayLoanByBatchOutput>('PayLoanByBatch', this.payment).toPromise();
    if (!response.approvalRequired && this.customerCache.loadedCustomerId) {
      this.customerCache.loans(this.customerCache.loadedCustomerId).refetch();
    }
    const errorsCount = response.output.payments.filter(p => p.errors?.length > 0);
    if (errorsCount.length > 0) {
      this.popup({text: `Processing skipped ${errorsCount.length} rows with errors. Proceed to history page to view them.`});
    }
    this.$route.reload();
  }

  categoryChanged = () => {
    this.categoryUnits = (this.category ? this.category.ledgerAccountFullCodes : [])
      .map(fullCode => ({
        fullCode,
        amount: 0
      }));

    if(this.category) {
      this.payment.categoryId = this.category.id;
    }

    this.clearSimulation();
  };
}

nxModule.component('batchLoanPaymentsUpload', {
  templateUrl,
  controller: BatchLoanPaymentsUpload,
});
