import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {IdResponse} from 'components/service/product.types';
import CollateralForm, {CollateralInput} from 'customer/collateral/Collateral';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import {ReactElement} from 'react';
import {useHistory, useParams} from 'react-router';

const CreateCollateral = (): ReactElement => {
  const {customerId} = useParams<{customerId: string}>();
  const history = useHistory();
  const execute = useCommand();

  return <NxPage>
    <NxHeader>Add collateral</NxHeader>
    <CollateralForm
      collateral={{
        customerId: Number(customerId)
      }}
     submitAction={async (collateral: CollateralInput) : Promise<void> => {
       const response: CommandOutputWrapper<IdResponse> = await execute<CollateralInput, IdResponse>({
         name: 'CreateCollateral',
         input: {
           customerId: collateral.customerId,
           securityId: collateral.securityId,
           guaranteeCustomerType: collateral.guaranteeCustomerType,
           guaranteeType: collateral.guaranteeType,
           holdId: collateral.holdId,
           name: collateral.name,
           assetCode: collateral.assetCode,
           ownerName: collateral.ownerName,
           address: collateral.address,
           description: collateral.description,
           remarks: collateral.remarks,
           appraisalDate: collateral.appraisalDate ?? collateral.holdOutDate,
           appraisalValue: collateral.appraisalValue ?? collateral.holdOutAmount,
           fileIds: collateral.fileIds
         }
       });

       if (!response.approvalRequired) {
         history.push(`/customer/${customerId}/collaterals`);
       }
     }}
    confirmationMessage='Are you sure that you want to create a collateral for this customer?'
    actionName='Add'
    commandAccessName='CreateCollateral'/>
  </NxPage>
}

export default CreateCollateral;
