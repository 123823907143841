import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './single-select-condition.template.html';
import './single-select-condition.style.less';

class SingleSelectConditionComponent {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.type = 'SINGLE_SELECT';

    this.$scope.$watchCollection('$ctrl.conditions', () => {
      if (!this.propertyDefinition) {
        return;
      }

      if (!this.isConditionAlreadyDefined()) {
        this.setDefaultValues();
      } else {
        this.fetchExistingValues();
      }
    });

    this.valueChanged = () => {
      this.saveValues();
    };
  }

  setDefaultValues() {
    this.model = {param: null};
  }

  fetchExistingValues() {
    this.model = this.conditions[this.propertyDefinition.property].model;
  }

  /**
   * Gets the only one allowed predicate for this type
   *
   * @returns {*}
   */
  getSupportedPredicate() {
    return this.propertyDefinition.supportedPredicates[0];
  }

  saveValues() {
    this.conditions[this.propertyDefinition.property] = {
      properties: [this.propertyDefinition.property],
      type: this.propertyDefinition.type,
      code: this.propertyDefinition.code,
      model: this.model,
      predicate: this.getSupportedPredicate(),
      options: []
    };
  }

  isConditionAlreadyDefined() {
    return _.has(this.conditions, this.propertyDefinition.property);
  }
}

nxModule.component('singleSelectCondition', {
  templateUrl,
  bindings: {
    conditions: '=',
    options: '=',
    propertyDefinition: '<'
  },
  controller: SingleSelectConditionComponent
});
