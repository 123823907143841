import nxModule from 'nxModule';

import templateUrl from './customer-risk-profile.template.html';
import './customer-risk-profile.style.less';

class CustomerRiskProfilePage {
  constructor(http, $route, dict, nav, command, popup, confirmation, customerCache, modalPrintPreviewService) {
    this.http = http;
    this.$route = $route;
    this.dict = dict;
    this.nav = nav;
    this.command = command;
    this.popup = popup;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.modalPrintPreviewService = modalPrintPreviewService;

    this.onModalApiReady = ({api,}) => {
      this.modalApi = api;
    };

    this.onSubcategoryModalApiReady = ({api,}) => {
      this.subcategoryModalApi = api;
    };
  }

  async $onInit() {
    this.dict.onLoadingComplete(() => this.customerRiskLevels = this.dict['CUSTOMER_RISK_LEVEL'].sort((a, b) => a.orderNo - b.orderNo));

    this.customerId = this.$route.current.params['customerId'];
    [this.riskCategories, this.riskProfileContainer, this.riskAssessments] = await Promise.all([
      this.http.get('/management/risk/categories').toPromise(),
      this.fetchCustomerRiskProfilePromise(),
      this.http.get('/management/risk/assessment').toPromise()
    ]);

    const {customerType} = await this.customerCache.profile(this.customerId).toPromise();
    this.riskCategoriesForManualSelection = this.riskCategories
      .filter(c => c.assignTo === 'NONE' || c.assignTo === 'SUBSTITUTED_VALUE')
      .filter(c => c.customerTypes.includes(customerType))
      .filter(c => {
        if (c.assignTo === 'SUBSTITUTED_VALUE') {
          return c.entries.some(e => e.conditions && 'substitutedValue' in e.conditions);
        }
        return c;
      })
      .map(c => this.buildValuesForDropdownOptions(c)) || [];
    this.loadRiskProfile();
  }

  buildValuesForDropdownOptions(c) {
    if (c.assignTo === 'NONE') {
      return {
        ...c,
        valuesForSelection: c.entries
      };
    } else if (c.assignTo === 'SUBSTITUTED_VALUE') {
      return {
        ...c,
        valuesForSelection: c.entries
            .flatMap(e => {
              if (JSON.stringify(e.conditions) === '{}') {
                return [{id: e.id, description: e.description}];
              } else if (JSON.stringify(e.conditions).includes('substitutedValue')) {
                return e.conditions.substitutedValue.options.map(o => ({
                      id: e.id,
                      description: o
                    })
                );
              } else {
                return [];
              }
            })
      };
    } else {
      return c;
    }
  }

  loadRiskProfile() {
    this.riskProfile = this.riskProfileContainer.riskProfile;
    this.riskProfileEntries = this.riskProfileContainer.entries.map(e => ({
      ...e,
      category: this.getCategory(e),
      categoryEntry: this.getCategoryEntry(e)
    }))
    .sort((a, b) => a.category.id - b.category.id);
  }

  fetchCustomerRiskProfilePromise() {
    return this.http.get(`/customers/${this.customerId}/risk-profile`).toPromise();
  }

  fetchRiskProfilePromiseById(riskProfileId) {
    return this.http.get(`/customers/risk-profile/${riskProfileId}`).toPromise();
  }

  async calculate() {
    if (this.riskCategoriesForManualSelection.length > 0) {
      const {accepted} = await this.subcategoryModalApi.show();
      if (!accepted) {
        return;
      }
    }
    this.disableCalculate = true;

    this.riskProfile = null;
    this.riskProfileEntries = null;
    this.selectedCategoryEntryIds =
      [...new Set(
        this.riskCategoriesForManualSelection.flatMap(rc => {
            if (rc.selectorType === 'SINGLE_SELECT') {
              return [rc.selectedSubcategoryIds];
            } else {
              return rc.selectedSubcategoryIds ? rc.selectedSubcategoryIds.map(s =>
                rc.valuesForSelection.find(v => v.description === s).id
              ) : [];
            }
          }
        )
      )];

    try {
      const response = await this.command.execute('CreateRiskProfile',
        {customerId: this.customerId, selectedCategoryEntryIds: this.selectedCategoryEntryIds}).toPromise();
      if (response.approvalRequired) {
        return;
      }

      this.riskProfileContainer = await this.fetchRiskProfilePromiseById(response.output.id);
      this.loadRiskProfile();
      this.customerCache.profile(this.customerId).refetch();

      this.popup({
        header: 'Result',
        text: 'New risk profile was generated.',
        renderHtml: true
      });
    } finally {
      this.disableCalculate = false;
    }
  }

  getCategory(profileEntry) {
    return this.riskCategories.find(c => c.id === profileEntry.riskCategoryId);
  }

  getCategoryEntry(profileEntry) {
    return this.riskCategories.flatMap(c => c.entries).find(e => e.id === profileEntry.riskCategoryEntryId);
  }

  goBack() {
    this.nav.back();
  }

  async override() {
    const { accepted } = await this.modalApi.show();
    if (!accepted) {
      this.overrideRemarks = null;
      this.overrideRiskDictionaryEntryId = null;
      return;
    }

    const response = await this.command.execute('OverrideRiskProfile', {
      customerId: this.customerId,
      overrideRemarks: this.overrideRemarks,
      riskDictionaryEntryId: this.overrideRiskDictionaryEntryId
    }).toPromise();

    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  async save() {
    const confirmation = await this.confirmation(`Do you want update this risk profile?`);
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute('UpdateRiskProfile', {riskProfile: this.riskProfile}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  getRiskLevelName(riskAssessment) {
    return this.dict.getDescription('CUSTOMER_RISK_LEVEL', riskAssessment.riskDictionaryEntryId);
  }

  async printCustomerRiskProfile() {
    await this.modalPrintPreviewService.showAsync({
      printDescription: {
        code: 'CUSTOMER_RISK_PROFILE'
      },
      printProviderInput: {
        customerId: this.customerId
      }
    });
  }
}

nxModule.component('customerRiskProfilePage', {
  templateUrl,
  controller: CustomerRiskProfilePage
});
