import {defaultResolver} from "./resolvers";
import Authentication from "../shared/utils/authentication";
import {NxRouteService} from "routes/NxRouteService";
import angular from "angular-route";
import {accessCheckByCommand, accessCheckByCommandAndBranchId} from "./routeUtils";

const dashboardPath = '/dashboard';
const miscTransactionsPath = `${dashboardPath}/miscellaneous-transactions`;

export const dashboardRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/user-settings', {
  template: '<user-settings/>'
  }],
  ['/support', {
    template: '<support-case-picker/>',
    label: 'support-topic'
  }],
  [`${dashboardPath}/actions`, {
    template: '<dashboard-action-list/>',
    label: 'Actions',
  }], [`${dashboardPath}/actions/sent-by-me`, {
    template: '<dashboard-action-list/>',
    label: undefined,
  }], [`${dashboardPath}/actions/:action/modes/:mode/objects/:object/view-profile`, {
    template: '<customer-profile-task-page task-id="$resolve.taskId" display-mode="$resolve.displayMode" customer-id="$resolve.customerId"/>',
    label: 'View Profile',
    resolve: {
      taskId: /* @ngInject */ ($route: NxRouteService): unknown => $route.current.params['action'],
      displayMode: /* @ngInject */ ($route: NxRouteService): unknown => $route.current.params['mode'],
      customerId: /* @ngInject */ ($route: NxRouteService): unknown => $route.current.params['object']
    }
  }], [`${dashboardPath}/day-management`, {
    template: '<day-management/>'
  }], [`${dashboardPath}/history/miscellaneous-transactions`, {
    template: '<history-miscellaneous-transactions/>',
    label: 'History'
  }], [`${dashboardPath}/new-customer`, {
    template: `<new-customer-page module="'customer'"/>`,
    label: 'New customer'
  }], [`${dashboardPath}/lookup-customer`, {
    template: '<lookup-customer/>',
    label: 'Lookup customer'
  }], [`${dashboardPath}/lookup-group`, {
    template: '<lookup-customer-group/>',
    label: 'Lookup group'
  }], [`${dashboardPath}/lookup-center`, {
    template: '<lookup-center/>',
    label: 'Lookup center'
  }], [`${miscTransactionsPath}`, {
    template: '<miscellaneous-transactions/>',
    label: 'Transactions'
  }], [`${miscTransactionsPath}/start-the-day`, {
    template: '<start-the-day branch-id="$resolve.branchId"/>',
    label: 'Start the day',
    resolve: {
      branchId: /* @ngInject */ (authentication: Authentication): number => Number(authentication.context.branchId)
    },
    resolveRedirectTo: accessCheckByCommand('StartDay')
  }], [`${miscTransactionsPath}/batch-start-the-day`, {
    template: '<batch-start-the-day/>',
    label: 'Centralized SOD',
    resolveRedirectTo: accessCheckByCommand('BatchStartDay')
  }], [`${miscTransactionsPath}/batch-start-the-day/:branchId`, {
    template: '<start-the-day branch-id="$resolve.branchId"/>',
    label: 'Individual SOD',
    resolve: {
      ...defaultResolver('branchId', true)
    },
    resolveRedirectTo: accessCheckByCommandAndBranchId('StartDay', 'BatchStartDay')
  }], [`${miscTransactionsPath}/cash-transfer-to-teller`, {
    template: `<cash-transfer command-name="'MiscCashTransferToTeller'"
                                interbranch="false"
                                header="'Cash transfer to teller'"
                  />`,
    label: 'Cash transfer to teller'
  }], [`${miscTransactionsPath}/cash-transfer-to-cashier`, {
    template: `<cash-transfer command-name="'MiscCashTransferToCashier'"
                                interbranch="false"
                                header="'Cash transfer to cashier'"
                  />`,
    label: 'Cash transfer to cashier'
  }], [`${miscTransactionsPath}/interbranch-cash-transfer`, {
    template: `<cash-transfer command-name="'MiscInterbranchCashTransfer'"
                                interbranch="true"
                                header="'Interbranch cash transfer'"
                  />`,
    label: 'Interbranch cash transfer'
  }], [`${miscTransactionsPath}/teller-cash-overage`, {
    template: '<teller-cash-overage/>',
    label: 'Teller Cash overage'
  }], [`${miscTransactionsPath}/teller-cash-shortage`, {
    template: '<teller-cash-shortage/>',
    label: 'Teller Cash shortage'
  }], [`${miscTransactionsPath}/invoice-in-cash`, {
    template: `<official-receipt asset-type="'CASH'"/>`,
    label: 'Invoice'
  }], [`${miscTransactionsPath}/invoice-in-check`, {
    template: `<official-receipt asset-type="'CHECK'"/>`,
    label: 'Invoice'
  }], [`${miscTransactionsPath}/interbranch-invoice-in-cash`, {
    template: `<official-receipt asset-type="'CASH'"
                                   interbranch="true"/>`,
    label: 'Interbranch invoice'
  }], [`${miscTransactionsPath}/interbranch-invoice-in-check`, {
    template: `<official-receipt asset-type="'CHECK'"
                                   interbranch="true"/>`,
    label: 'Interbranch invoice'
  }], [`${miscTransactionsPath}/pos-cash-withdrawal`, {
    template: '<pos-cash-withdrawal/>',
    label: 'POS cash withdrawal'
  }], [`${miscTransactionsPath}/create-cashiers-check`, {
    template: '<create-cashiers-check interbranch="false"/>',
    label: 'create-cashiers-check-label'
  }], [`${miscTransactionsPath}/interbranch-create-cashiers-check`, {
    template: '<create-cashiers-check interbranch="true"/>',
    label: 'create-cashiers-check-label'
  }], [`${miscTransactionsPath}/close-counters`, {
    template: '<close-counters/>',
    label: 'Close counters'
  }], [`${miscTransactionsPath}/end-the-day`, {
    template: `<end-the-day branch-id="$resolve.branchId"
                              auto-cc-and-sod="false"/>`,
    label: 'End the day',
    resolve: {
      branchId: /* @ngInject */ (authentication: Authentication): number => Number(authentication.context.branchId)
    },
    resolveRedirectTo: accessCheckByCommand('EndDay')
  }], [`${miscTransactionsPath}/end-the-day-with-auto-cc-sod`, {
    template: `<end-the-day branch-id="$resolve.branchId"
                              auto-cc-and-sod="true"/>`,
    label: 'End the Day (with automatic close counter and start of day)',
    resolve: {
      branchId: /* @ngInject */ (authentication: Authentication): number => Number(authentication.context.branchId)
    },
    resolveRedirectTo: accessCheckByCommand('EndDayWithCloseCounterAndStartDay')
  }], [`${miscTransactionsPath}/batch-end-the-day`, {
    template: '<batch-end-the-day/>',
    label: 'Centralized EOD',
    resolveRedirectTo: accessCheckByCommand('BatchEndDay')
  }], [`${miscTransactionsPath}/batch-end-the-day/:branchId`, {
    template: '<end-the-day branch-id="$resolve.branchId"/>',
    label: 'End the day',
    resolve: {
      ...defaultResolver('branchId', true)
    },
    resolveRedirectTo: accessCheckByCommandAndBranchId('EndDay', 'BatchEndDay')
  }], [`${miscTransactionsPath}/check-transfer-to-cashier`, {
    template: '<check-transfer-to-cashier/>',
    label: 'Check transfer to cashier'
  }], [`${miscTransactionsPath}/misc-cash-disbursement`, {
    template: '<misc-cash-disbursement/>',
    label: 'Misc cash disbursement'
  }], [`${miscTransactionsPath}/cashier-cash-overage`, {
    template: '<cashier-cash-overage/>',
    label: 'Cashier cash overage'
  }], [`${miscTransactionsPath}/cashier-cash-shortage`, {
    template: '<cashier-cash-shortage/>',
    label: 'Cashier cash shortage'
  }], [`${miscTransactionsPath}/batch-check-deposit-to-bank`, {
    template: `<check-deposit interbranch="false" target="'DEPOSITORY_BANK'"/>`,
    label: 'Batch check deposit to depository bank'
  }], [`${miscTransactionsPath}/interbranch-batch-check-deposit-to-bank`, {
    template: `<check-deposit interbranch="true" target="'DEPOSITORY_BANK'"/>`,
    label: 'Interbranch batch check deposit to depository bank'
  }], [`${miscTransactionsPath}/funds-movement/:actionType`, {
    template: '<funds-movement action-type="$resolve.actionType"/>',
    label: 'Funds movement',
    resolve: {
      ...defaultResolver('actionType')
    }
  }], [`${miscTransactionsPath}/inter-branch-cash-in`, {
    template: '<inter-branch-cash-in/>',
    label: 'Inter branch cash in'
  }], [`${miscTransactionsPath}/sss-online-payment`, {
    template: '<sss-online-payment/>',
    label: 'SSS - PRN online payment'
  }], [`${miscTransactionsPath}/sss-offline-payment`, {
    template: '<sss-offline-payment/>',
    label: 'SSS - SBR-Less offline payment'
  }], [`${miscTransactionsPath}/sss-change-individual-prn`, {
    template: '<sss-change-individual-prn/>',
    label: 'SSS - Generate Individual PRN'
  }], [`${miscTransactionsPath}/schedule-accounting-period-closure`, {
    template: '<schedule-accounting-period-closure/>',
    label: 'Schedule accounting period closure'
  }], [`${miscTransactionsPath}/cash-out`, {
    template: `<cash-in-out direction="'OUT'" fixed-unit-type="'CREDIT'"
      />`,
    label: 'Cash Out'
  }], [`${miscTransactionsPath}/atm-cash-out`, {
    template: `<cash-in-out direction="'OUT'" fixed-unit-type="'CREDIT'" atm-transaction="true"
    />`,
    label: 'ATM - Cash Out'
  }], [`${miscTransactionsPath}/atm-cash-withdrawal`, {
    template: `<atm-cash-withdrawal/>`,
    label: 'ATM - Cash withdrawal'
  }], [`${miscTransactionsPath}/interbranch-cash-out`, {
    template: `<cash-in-out interbranch="true"
                              direction="'OUT'"
                              fixed-unit-type="'CREDIT'"
      />`,
    label: 'Interbranch Cash Out'
  }], [`${miscTransactionsPath}/cash-in`, {
    template: `<cash-in-out direction="'IN'" fixed-unit-type="'DEBIT'"
      />`,
    label: 'Cash In'
  }], [`${miscTransactionsPath}/atm-cash-in`, {
    template: `<cash-in-out direction="'IN'" fixed-unit-type="'DEBIT'" atm-transaction="true"
      />`,
    label: 'ATM - Cash In'
  }], [`${miscTransactionsPath}/interbranch-cash-in`, {
    template: `<cash-in-out interbranch="true"
                                direction="'IN'"
                                fixed-unit-type="'DEBIT'"
      />`,
    label: 'Interbranch Cash In'
  }], [`${miscTransactionsPath}/cash-to-depository-bank`, {
    template: `<cash-to-from-bank direction="'TO'"
                                    interbranch="false"
      />`,
    label: 'Cash to depository bank'
  }], [`${miscTransactionsPath}/interbranch-cash-to-depository-bank`, {
    template: `<cash-to-from-bank direction="'TO'"
                                    interbranch="true"
      />`,
    label: 'Interbranch cash to depository bank'
  }], [`${miscTransactionsPath}/cash-withdrawal-from-depository-bank`, {
    template: `<cash-to-from-bank direction="'FROM'"
                                    interbranch="false"
      />`,
    label: 'Cash withdrawal from depository bank'
  }], [`${miscTransactionsPath}/interbranch-cash-withdrawal-from-depository-bank`, {
    template: `<cash-to-from-bank direction="'FROM'"
                                    interbranch="true"
      />`,
    label: 'Interbranch cash withdrawal from depository bank'
  }], [`${miscTransactionsPath}/batch-check-deposit-to-branch`, {
    template: `<check-deposit interbranch="false" target="'BRANCH'"/>`,
    label: 'Batch check deposit to branch'
  }], [`${dashboardPath}/collections`, {
    template: '<collections/>',
    label: 'Collections'
  }], [`${dashboardPath}/health-check`, {
    template: '<system-health-check/>',
    label: 'Health Check',
  }]
];
