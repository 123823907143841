import BigNumber from 'bignumber.js';
import {renewedLoanCreationTypes} from 'constants/loan';
import _ from 'lodash';
import moment from 'moment';

import nxModule from 'nxModule';
import PrintService from 'print/PrintService';
import ReportService from 'report/ReportService';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {Subscription} from 'rxjs/Subscription';
import systemPropertyService from '../../../../react/system/systemPropertyService';
import './amortization/amortization-custom-fees-inline-panel.component';
import './amortization/amortization-inline-panel.component';
import './assign-group/customer-loans-assign-group.component';
import './cash-payment/loan-cash-payment.component';
import './check-payment/loan-check-payment.component';
import './check-release/group-loan-check-release.component';
import './check-release/loan-check-release.component';

import './common/amortization-payment.component';
import './common/discount-charges-ledger.component';
import './common/loan-pre-terminate.component';
import './common/paid-amortizations.component';
import './common/uid-ledger.component';
import './consolidation/loan-consolidation.component'
import './delete-loan/delete-loan-confirmation.component';
import './documents/loan-documents.component';
import './documents/loan-edit-documents.component';
import './gl-payment';
import './loan-account-information/loan-account-information.component';
import './new-loan/list/customer-loan-create-list.component';
import './new-loan/list/list-table/customer-loan-create-list-table.component';
import './new-loan/page/customer-loan-create-page.component';
import './new-loan/page/customer-loan-create.service';
import './new-loan/page/customer-loan-restructure-page.component';
import './new-loan/page/details/charges-override/customer-loan-charges-override.component';
import './new-loan/page/details/co-makers/customer-loan-co-makers.component';
import './new-loan/page/details/customer-loan-create-details.component';
import './new-loan/page/details/lai/customer-loan-create-lai-details.component';
import './new-loan/page/details/lai/custom-field-input.component';
import './new-loan/page/details/negative-information/negative-information.component';
import './new-loan/page/details/parameters/customer-loan-create-parameters.component';
import './new-loan/page/renewal/customer-loan-renewal-page.component';
import './new-loan/page/renewal/customer-loan-renewal.service';
import './payment-revert/loans-payment-revert-to-gl.component';
import './pdc/edit/loan-edit-pdc.component';
import './pdc/issue/loan-issue-pdc.component';
import './pdc/tab/loan-pdc-tab.component';
import './pre-terminate/loan-pre-terminate-by-cash.component';
import './pre-terminate/loan-pre-terminate-by-gl.component';
import './pre-terminate/loan-pre-terminate-by-transfer.component';
import './pre-terminate/loan-pre-terminate.service';

import './preview/loan-preview.component';
import './recreate-discount-charges-ledger/recreate-discount-charges-ledger.component';
import './release/customer-loan-release.component';
import './release/group-loan-cash-release.component';
import './ropa/loan-ropa-transfer.component';
import './transfer-payment/loan-transfer-payment.component';
import './waive-penalties/waive-amortization.component';

const templateUrl = require('./customer-loans.template.html');
const printService = new PrintService();
const reportService = new ReportService();
nxModule.component('customerLoans', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, customerCache, notification, systemDateService, userCache,
                        modalPrintPreviewService, command, reportModal, loanProductsCache, branchService,
                        transactionDetailsBuilder, confirmation, confirmationTemplate, authentication, revertCommandService, $routeParams,
                        feeDefinitionsCache, queryParamsRemover, NgTableParams, $filter, customerService, popup,
                        operationService, productService, http,
                        customFieldCategoryDetailsCache, productLockCache) {
    let that = this;
    that.loans = [];
    that.coMakerProfilesByLoan = {};
    that.visibleLoans = [];
    that.showClosed = $routeParams.showClosed === "true";
    that.selectedLoan = undefined;
    that.selectedLoanPenalty = undefined;
    that.selectedLoanCoMakers = [];
    that.item = undefined;
    that.selectedTransactionId = undefined;
    $scope.permission = authentication.permissions;
    that.commandAccess = command.access;
    that.isLoanPaymentReceiptActive = false;
    that.depositAccounts = {};
    that.branchSystemDate = null;
    that.$filter = $filter;
    that.displayNewHistory = undefined;
    that.isLoanLocked = false;
    that.loanTableData = [];
    that.columnConfiguration = null;
    that.amortizationTable = new NgTableParams({
      count: that.loanTableData.length
    }, {
      counts: [],
      getData: () => that.loanTableData
    });
    that.approvedLoan = undefined;
    that.profile = undefined;
    that.ongoingStatementOfAccountPrint = false;
    that.ongoingDisclosureStatementPrint = false;
    that.ongoingPromissoryNotePrint = false;

    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);

    let customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.isAddOnRateAmortizationType = () => {
      return that.selectedLoan?.interestType === 'ADD_ON_RATE'
        && that.selectedLoan?.loanType.amortizationType === 'DIMINISHING_EQUAL_AMORTIZATION'
    }

    that.reallocateOperationGroupsTriggeredOnLoanTransferToAnotherBranch = [
      'REALLOCATE_PRODUCT_BALANCE',
      'REALLOCATE_UID',
      'REALLOCATE_ACCRUED_INTEREST',
      'REALLOCATE_DISCOUNT_CHARGES'
    ];

    that.groups = ["DEPOSIT_CASH", "DEPOSIT_CHECK", "DEPOSIT_CUTOFF_CHECK", "TRANSFER_FUNDS",
      "CREATE_PRODUCT", "UPDATE_STATUS", "APPLY_FEE", "CREATE_PRODUCT", "CREDIT_MEMO",
      "REALLOCATE_INTEREST", ...that.reallocateOperationGroupsTriggeredOnLoanTransferToAnotherBranch,
      "ROPA_TRANSFER", "COLLECT_ADVANCE_INTEREST", "GL_TRANSFER"
    ];

    that.lockMessageMapping = [
      {
        lockType: 'RENEWAL_FREEZE',
        message: 'Loan renewal process in progress. Operations are not available.',
      },
      {
        lockType: 'RESTRUCTURING_FREEZE',
        message: 'Loan restructure process in progress. Operations are not available.',
      },
      {
        lockType: 'LOS_CONSOLIDATION',
        message: 'This loan account is currently under consolidation in LOS.'
      }
    ];

    const propertyValue = systemPropertyService.getProperty('SHOW_NEW_LOAN_HISTORY');
    that.displayNewHistory = propertyValue === "TRUE";

    that.loanIsReleasedButNotClosed = (loan) => {
      return loan && !['PENDING', 'INACTIVE', 'CLOSED', 'ROPA'].includes(loan.status);
    };

    that.hasAccruedLedgerEntries = (loan) => {
      if (!loan || !loan.uidLedger) {
        return false;
      }
      return loan.uidLedger.list.some(a => a.status === 'ACCRUED');
    };

    that.isLoanAssignToGroupAllowed = loan => {
      return loan && !['PENDING', 'CLOSED', 'ROPA'].includes(loan.status);
    };

    that.isLoanWrittenOff = () => {
      return that.selectedLoan?.status === 'PAST_DUE_WRITE_OFF';
    }

    that.isLoanLockedLOSConsolidation = () => {
      // Check if consolidated loans has origin loan
      const foundRemadeLoan = that.visibleLoans?.reduce((acc, loan) => {
        if (loan.remadeFromLoanIds?.length) {
          acc.push(...loan.remadeFromLoanIds);
        }
        return acc;
      }, []);

      const hasConsolidatedLoan = foundRemadeLoan?.find(loan => loan === that?.selectedLoan?.id)
      return (that.selectedLoan?.origin === 'LOS' && that.selectedLoan?.creationType === 'CONSOLIDATION') || !!hasConsolidatedLoan
    }

    const isLoanLocked = async () => {
      if (that.loanId) {
        const productLocks = await productLockCache.withParam(Number(that.loanId)).toPromise();
        that.isLoanLocked = productLocks.some(l => !l.released);

        if (!that.isLoanLocked && !that.isLoanLockedLOSConsolidation()) {
          return;
        }

        const getLockMessage = (lockType) => that.lockMessageMapping.find(lm => lm.lockType === lockType)?.message;
        const lock = productLocks.find(l => l.productId === Number(that.loanId));
        const lockType = that.isLoanLockedLOSConsolidation() ? 'LOS_CONSOLIDATION' : lock?.type;
  
        that.lockMessage = getLockMessage(lockType);
      }
    };

    isLoanLocked();

    customerCache.depositAccounts(customerId).toObservable().first()
      .subscribe(data => {
        that.depositAccounts = data.filter(a => ['ACTIVE', 'INACTIVE', 'CLOSED'].includes(a.status));
      });

    modalPrintPreviewService.canReprint('LOAN_PAYMENT_RECEIPT', (active) => {
      that.isLoanPaymentReceiptActive = active;
    });

    modalPrintPreviewService.canReprint('LOAN_RELEASE_BY_CASH_VALIDATION', (active) => {
      that.isLoanReleaseByCashValidationReprintable = active;
    });

    modalPrintPreviewService.canReprint('LOAN_RELEASE_GROUP_BY_CASH_VALIDATION', (active) => {
      that.isLoanReleaseGroupByCashValidationReprintable = active;
    });

    that.toggleClosedLoans = () => {
      if (that.showClosed) {
        that.visibleLoans = that.loans;
      } else {
        that.visibleLoans = that.loans.filter(loan => loan.status !== 'CLOSED');
      }

      if (that.selectedLoan) {
        $location
          .path(`/customer/${customerId}/loans/${that.selectedLoan.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.hideTransactionDetails = () => {
      that.selectedTransactionId = null;
    };


    that.transactionClicked = (transaction, $event) => {
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
    };

    that.hasZeroOutstandingBalance = loan => {
      if (!loan) {
        return false;
      }

      const totalBalance = _.get(loan, 'amortizationSchedule.totalBalance', {});
      return Object.values(totalBalance).every(balanceValue => balanceValue === 0);
    };

    that.setCellColor = (row, col) => {
      if (col.isStatus) {
        return {
          'red': row.status === 'DUE' || row.status === 'OVERDUE',
          'green': row.status === 'PAID',
          'orange': row.status === 'PARTIALLY_PAID'
        }
      } else if (col.isLate) {
        return {'red': row.daysLate > 0 && row.status !== 'PAID'}
      } else if (row['No'] === 'Outstanding:' && col.totalCheck) {
        return {
          'text-alert': that.selectedLoan.amortizationSchedule.totalBalance.interest !== that.selectedLoan.interestBalance
        }
      } else if (row['No'] === 'Outstanding:' && col.principalCheck) {
        return {
          'text-alert': that.selectedLoan.amortizationSchedule.totalBalance.principal !== that.selectedLoan.principalBalance
        }
      }
      return null;
    };

    that.addTotal = (config, totalObject, title) => {
      let total = {};

      config.forEach(option => {
        const field = option.field.replace('Amount', '');

        if (typeof totalObject[field] === 'number') {
          total[option.field] = totalObject[field];
        } else if (option.field === 'amortizationAmount') {
          total[option.field] = totalObject['total'];
        } else {
          total[option.field] = null;
        }
      });

      total['No'] = title;
      return total;
    };

    that.prepareAmortizationTable = schedule => {
      let scheduleList = schedule.list;

      const showAmount = field => {
        const acceptableFields = ['cbuChargeAmount', 'pfChargeAmount', 'tpChargeAmount'];
        const isFieldIncluded = acceptableFields.includes(field);

        if (!isFieldIncluded) {
          return true;
        }

        return scheduleList
          .map(item => item[field])
          .some(val => val !== 0);
      };

      that.columnConfiguration = [
        {field: "No", title: "No", show: true},
        {field: "dueDate", title: "Due Date", show: true, filterName: 'prettyDateWithDay'},
        {field: "status", title: "Status", show: true, isStatus: true},
        {field: "datePaid", title: "Date paid", show: true, filterName: 'prettyDate'},
        {field: "daysLate", title: "Days late", show: true, isLate: true},
        {field: "principalAmount", title: "Principal", show: true, filterName: 'nxCurrency', principalCheck: true},
        {field: "interestAmount", title: "Interests", show: true, filterName: 'nxCurrency', interestCheck: true},
        {field: "cbuChargeAmount", title: "CBU", show: true, filterName: 'nxCurrency'},
        {field: "pfChargeAmount", title: "PF", show: true, filterName: 'nxCurrency'},
        {field: "tpChargeAmount", title: "TP", show: true, filterName: 'nxCurrency'},
        {field: "customFeesAmount", title: "Custom Fees", show: true, filterName: 'nxCurrency'},
        {field: "amortizationAmount", title: "Total amount", show: true, filterName: 'nxCurrency'},
        {field: "loanOutstandingBalance", title: "Balance after", show: true, filterName: 'nxCurrency'}
      ].map(option => ({...option, show: option.filterName === 'nxCurrency' ? showAmount(option.field) : true}));

      scheduleList = scheduleList.map((val, index) => ({...val, No: index + 1}));

      scheduleList.push(that.addTotal(that.columnConfiguration, schedule.totalAmount, 'Total:'));
      scheduleList.push(that.addTotal(that.columnConfiguration, schedule.totalBalance, 'Outstanding:'));

      return scheduleList;
    };

    const assignContractualSavingsAccounts = loan => {
      loan.cbuAccount = _.find(that.depositAccounts, {id: loan.cbuSavingsAccountId});
      loan.pfAccount = _.find(that.depositAccounts, {id: loan.pfSavingsAccountId});
      loan.tpAccount = _.find(that.depositAccounts, {id: loan.tpSavingsAccountId});

      that.selectedLoan = loan;
    };

    const assignLinkedDepositAccount = loan => {
      loan.linkedDepositAccount = _.find(that.depositAccounts, {id: loan.linkedDepositAccountId});

      that.selectedLoan = loan;
    };

    const assignCoMakers = async loan => {
      if (!loan.coMakers || loan.coMakers.length === 0) {
        that.selectedLoanCoMakers = [];
        return;
      }
      that.selectedLoanCoMakers = await http.get('/customers/all?ids=' + loan.coMakers.map(c => c.customerId).join(",")).toPromise();
    };

    const fetchLoanPDCs = async (loan) => {
      const loanPDCs = await customerCache.loanPDCs(customerId, loan.id).toPromise();
      that.loanPDCsExist = !_.isEmpty(loanPDCs || []);
    };

    const setPenaltyConfig = async loan => {
      that.selectedLoanPenalty = await fetchPenaltyConfig(loan);
    }

    const fetchPenaltyConfig = async (loan) => {
      const penalty = await http.get(`/products/loans/${loan.id}/product-fee-definition/evaluate?feeClass=PENALTY`).toPromise();
      const isFixedAmount = penalty.calculationMethod === 'FIXED_AMOUNT';
      return {
        rate: isFixedAmount ? penalty.fixedAmount : penalty.percentageAmount,
        type: penalty.calculationMethod
      }
    }

    const fetchLetters = async (loan) => {
      const letters = await http.get(`/products/loans/${loan.id}/letters`).toPromise();
      that.loanLetters = letters ?? [];
    };

    const fetchRemadeToLoan = async (loan) => {
      if (!loan.remadeTo) {
        return;
      }
      that.remadeToLoans = await http.get(`/products/quick-search?ids=${[loan.remadeTo]}`).toPromise();
    }

    const fetchApprovedLoanDetails = async (loan) => {
      if (loan.checkPreparationId) {
        that.approvedLoan = await http.get(`/check-preparation/${loan.checkPreparationId}/approved-loan`).toPromise();
      }
    };

    that.selectLoan = (loan) => {
      assignContractualSavingsAccounts(loan);
      assignLinkedDepositAccount(loan);
      setPenaltyConfig(loan);

      $location.path(`/customer/${customerId}/loans/${loan.id}`)
    };

    that.commandAccessContext = _.memoize((ln, profile) => {
      if (!ln && !profile) {
        return null;
      }

      if (!ln && profile) {
        return {
          customer: that.profile
        }
      }

      return {
        branchId: ln.branchId,
        productStatus: ln.status,
        releaseDate: ln.releaseDate,
        branchSystemDate: that.branchSystemDate,
        customer: that.profile
      };
    });

    that.isGrantDateValid = () => {
      return that.branchSystemDate.isSameOrAfter(moment(that.selectedLoan.grantDate));
    };

    that.createNew = () => $location.path(`/customer/${customerId}/loans/create`);
    that.consolidate = () => $location.path(`/customer/${customerId}/loans/consolidate`);
    that.release = () => $location.path(`/dashboard/miscellaneous-transactions/cashiers-check-creation`);
    that.payInCash = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/cash-payment`);
    that.payByTransfer = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/transfer-payment`);
    that.payInCheck = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/check-payment`);
    that.payByGeneralLedger = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/gl-payment`);
    that.revertPaymentToGl = (operationId) => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/revert-payment-to-gl/${operationId}`);
    that.transferToRopa = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/ropa-transfer`);
    that.uidRebate = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/rebate`);

    const hasValidContractualSavingsAccount = () => {
      let isValid = true;
      let errMessage = '';
      if (that.selectedLoan) {
        if (that.selectedLoan.cbuAccount && that.selectedLoan.cbuAccount.status === 'CLOSED') {
          errMessage = `CBU account ${that.selectedLoan.cbuAccount.productNumber}`;
          isValid = false;
        } else if (that.selectedLoan.pfAccount && that.selectedLoan.pfAccount.status === 'CLOSED') {
          errMessage = `PF account ${that.selectedLoan.pfAccount.productNumber}`;
          isValid = false;
        } else if (that.selectedLoan.tpAccount && that.selectedLoan.tpAccount.status === 'CLOSED') {
          errMessage = `TP account ${that.selectedLoan.tpAccount.productNumber}`;
          isValid = false;
        }
      }

      if (!isValid) {
        popup({text: errMessage.concat(' assigned to this loan is already closed. Please make sure to re-open this account to be able to perform transactions with this Loan.')});
      }

      return isValid;
    };

    that.preTerminateByCash = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/pre-terminate/cash`);
    };
    that.preTerminateByTransfer = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/pre-terminate/transfer`);
    };
    that.preTerminateByGL = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/pre-terminate/general-ledger`);
    };

    that.payInCash = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/cash-payment`);
    };

    that.payByTransfer = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/transfer-payment`);
    };

    that.payInCheck = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/check-payment`);
    };

    that.payByGeneralLedger = () => {
      if (hasValidContractualSavingsAccount())
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/gl-payment`);
    };

    that.revertCommandName = (operation, purpose) => {
      return revertCommandService.revertCommandName(operation, purpose);
    };

    that.revertEntryCommandName = (entry, purpose) => {
      if (!entry) {
        return null;
      }
      return revertCommandService.revertEntryCommandName(entry, purpose);
    };

    that.revertAllowed = (operation, commandName) => {
      if (!operation || !that.loanIsReleasedButNotClosed(that.selectedLoan)) {
        return false;
      }
      // 'Revert' button can't be visible on 'DEPOSIT_CHECK' operation for late check
      // as it can't be reverted because it's created by SoD command.
      let isLateCheckDeposit = operation.operationGroup === 'DEPOSIT_CHECK' && operation.attributes['LATE_CHECK'];
      return commandName &&
        that.isAfterLastLoanReallocation([operation.id]) &&
        that.hasNoPaymentsAfter([operation.id], operation.commandId) &&
        operation.target.id.toString() === that.loanId &&
        ['PENDING', 'PROCESSED'].includes(operation.status) &&
        !isLateCheckDeposit &&
        (!operation.attributes['RETURN_CHECK_OPERATION_ID'] || !operation.attributes['GL_TRANSFER_OPERATION_ID']) &&
        operation.attributes['PAYMENT_TYPE'] !== 'REBATE' &&
        !operation.revertOperationId &&
        revertCommandService.revertAllowed(operation, commandName);
    };

    that.rebateRevertAllowed = (entry) => {
      if (!entry || !entry.operationIds || !that.loanIsReleasedButNotClosed(that.selectedLoan)) {
        return false;
      }
      const branchSystemDate = moment(entry.branchWorkingDay.systemDate);
      const entrySystemDate = moment(entry.systemDate);
      return !branchSystemDate.isAfter(entrySystemDate) &&
        that.isAfterLastLoanReallocation(entry.operationIds) &&
        entry.operationIds.length === 1 &&
        entry.productId === Number(that.loanId) &&
        ['PENDING', 'PROCESSED'].includes(entry.status) &&
        !entry.revertedByEntryId &&
        !entry.entryIdReverted &&
        revertCommandService.revertAllowed(entry, "UidRebateRevert");
    };

    that.revertWriteOffAllowed = (entry) => {
      if (!entry || !entry.operationIds || !that.loanIsReleasedButNotClosed(that.selectedLoan)) {
        return false;
      }
      const branchSystemDate = moment(entry.branchWorkingDay.systemDate);
      const entrySystemDate = moment(entry.systemDate);
      return !branchSystemDate.isAfter(entrySystemDate) &&
        that.hasNoPaymentsAfter(entry.operationIds, entry.commandId) &&
        entry.productId === Number(that.loanId) &&
        that.selectedLoan.status === 'PAST_DUE_WRITE_OFF' &&
        ['PENDING', 'PROCESSED'].includes(entry.status) &&
        revertCommandService.revertAllowed(entry, "WriteOffLoanRevert");
    };

    that.revertToCustomGlAllowed = (operation, commandName) => {
      if (!operation) {
        return false;
      }

      const branchSystemDate = moment(operation.branchWorkingDay.systemDate);
      const operationSystemDate = moment(operation.systemDate);
      return branchSystemDate.isAfter(operationSystemDate) && that.revertAllowed(operation, commandName);
    };

    that.revertEntryAllowed = (entry, commandName) => {
      if (!entry || !entry.operationIds || !that.loanIsReleasedButNotClosed(that.selectedLoan)) {
        return false;
      }
      const operationToRevert = entry?.operationIds?.length === 1 ? that.productOperations.find(o => o.id === entry.operationIds[0]) : null;

      //payments done while loan is written off can only be reverted if loan is still in write-off status
      const canRevertWriteOffOperation = !operationToRevert
        || that.selectedLoan.status !== 'PAST_DUE_WRITE_OFF'
        || operationToRevert.target.status === 'PAST_DUE_WRITE_OFF';


      return commandName &&
        that.isAfterLastLoanReallocation(entry.operationIds) &&
        canRevertWriteOffOperation &&
        entry?.attributes['RELEASE_METHOD'] !== 'RESTRUCTURE' &&
        that.hasNoPaymentsAfter(entry.operationIds, entry.commandId) &&
        entry.productId === Number(that.loanId) &&
        entry.operationIds.length === 1 &&
        ['PENDING', 'PROCESSED'].includes(entry.status) &&
        !entry.revertedByEntryId &&
        !entry.entryIdReverted &&
        entry.attributes['PAYMENT_TYPE'] !== 'REBATE' &&
        !entry.attributes['GL_TRANSFER_OPERATION_ID'] &&
        revertCommandService.revertAllowed(entry, commandName);
    };

    that.revertEntryToCustomGlAllowed = (entry, commandName) => {
      if (!entry || !entry.operationIds) {
        return false;
      }

      const branchSystemDate = moment(entry.branchWorkingDay.systemDate);
      const entrySystemDate = moment(entry.systemDate);
      return branchSystemDate.isAfter(entrySystemDate) && that.revertEntryAllowed(entry, commandName);
    };

    that.hasNoPaymentsAfter = (operationIds, commandId) => {
      if (!operationIds || operationIds.length === 0) {
        return false;
      }

      const proceedingOperations = that.getReversiblePaymentOperations()
        .filter(o =>
          !operationIds.includes(o.id)
          // Operations coming from the same command will be reverted together.
          && o.commandId !== commandId
          && operationIds.every(id => id < o.id)
          && o.target.id === that.selectedLoan.id
        );
      return proceedingOperations.length < 1;
    };

    that.isAfterLastLoanReallocation = (operationIds) => {
      const lastTransferOperation = that.productOperations
        .filter(o => that.reallocateOperationGroupsTriggeredOnLoanTransferToAnotherBranch.includes(o.operationGroup))
        .filter(o => {
          const hasSourceAndTargetBranches = o.source && o.target;
          const isInterBranchTransfer = o.source?.branchId !== o.target.branchId;
          return hasSourceAndTargetBranches && isInterBranchTransfer;
        })[0]
      return !lastTransferOperation || operationIds.every(id => id > lastTransferOperation.id);
    }

    that.revertPayment = (operation) => confirmation(`Do you want to revert the payment of ${$filter('nxCurrency')(operation.amount)}?`, () => {
      let revertCommand = that.revertCommandName(operation, 'LOAN_PAYMENT_REVERT');

      command.execute(revertCommand, {operationId: operation.id, commandId: operation.commandId})
        .success(() => {
          notification.show('Successfully reverted payment');
          customerCache.loans(customerId).refetch();
          customerCache.loanPDCs(customerId, that.selectedLoan.id).refetch();
          authentication.permissions['CST_CREDIT_LINE_READ'] ?  customerCache.creditLines(customerId).refetch() : Promise.resolve();
          customerCache.depositAccounts(customerId).refetch();
          $route.reload();
        });
    });

    that.revertPaymentFromEntry = (entry) => confirmation(`Do you want to revert the payment of ${$filter('nxCurrency')(entry.amount)}?`, () => {
      let revertCommand = that.revertEntryCommandName(entry, 'LOAN_PAYMENT_REVERT');

      command.execute(revertCommand, {operationId: entry.operationIds[0], commandId: entry.commandId})
        .success(() => {
          notification.show('Successfully reverted payment');
          customerCache.loans(customerId).refetch();
          customerCache.loanPDCs(customerId, that.selectedLoan.id).refetch();
          customerCache.creditLines(customerId).refetch();
          customerCache.depositAccounts(customerId).refetch();
          $route.reload();
        });
    });

    that.revertRebate = (entry) => confirmation(`Do you want to revert the UID rebate? This will revert the uid application for ${$filter('nxCurrency')(entry.amount)} as well as the rebate loan payment.`, () => {

      command.execute("UidRebateRevert", {operationId: entry.operationIds[0], commandId: entry.commandId})
        .success(() => {
          notification.show('Successfully reverted rebate');
          customerCache.loans(customerId).refetch();
          $route.reload();
        });
    });

    that.revertWriteOff = async (entry) => {
      const confirmed = await confirmation(`Do you want to revert the write off operation performed for ${that.selectedLoan.productNumber}`);
      if(!confirmed) {
        return;
      }
      const response = await command.execute("WriteOffLoanRevert", {operationId: entry.operationIds[0], commandId: entry.commandId});
      if(!response.approvalRequired) {
        notification.show('Successfully reverted write off');
        customerCache.loans(customerId).refetch();
        $route.reload();
      }
    };

    that.paymentReport = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/payment-report`);
    };

    that.rebatesLedgerReport = () => {
      let params = {
        productId: that.loanId
      };
      let reportName = 'RebatesLedgerReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };

    that.amortizationScheduleReport = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/schedule-report`);
    };

    that.statementOfAccountPerAmortization = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/statement`);
    };

    that.statementOfAccountTotal = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/statement-total`);
    };

    that.statementOfAccount = async () => {
      if(!that.ongoingStatementOfAccountPrint){
        that.ongoingStatementOfAccountPrint = true;
        await reportService.downloadFileReport({reportName : 'Statement of Account', parameters: { loanId: parseInt(that.loanId), userId: authentication.context.id}});
        that.ongoingStatementOfAccountPrint = false;
      }

    };

    that.promissoryNote = async () => {
      if (that.reportingToolPromissoryNotePresent) {
        if(!that.ongoingPromissoryNotePrint){
          that.ongoingPromissoryNotePrint = true;
          await reportService.downloadFileReport({reportName : 'Promissory Note', parameters: { loanId: parseInt(that.loanId), userId: authentication.context.id}});
          that.ongoingPromissoryNotePrint = false;
        }
      } else {
        await modalPrintPreviewService.showAsync({
          printDescription: {
            code: 'LOAN_PROMISSORY_NOTE',
            parameters: {
              'PRODUCT_DEFINITION': that.selectedLoan.definitionId.toString(),
              'CUSTOMER_TYPE': that.profile.customerType
            }
          },
          printProviderInput: {
            loanId: that.loanId
          }
        });
      }
    };

    that.letters = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/letters`)
    };

    that.isLoanInquiryPrintActive = false;
    printService.getPrint({printCode: 'LOAN_INQUIRY'}).then(print => {
      that.isLoanInquiryPrintActive = print.active;
    });

    that.loanInquiry = async () => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'LOAN_INQUIRY',
        printProviderInput: {
          loanId: that.loanId
        }
      });
    };

    that.statementOfAccountActive = false;
    that.reportingToolDisclosureStatementPresent = false;
    that.reportingToolPromissoryNotePresent = false;
    reportService.readAvailableFileReports().then(reports => {
      if(reports.includes('Statement of Account')){
        that.statementOfAccountActive = true;
      }
      if(reports.includes('Disclosure Statement')){
        that.reportingToolDisclosureStatementPresent = true;
      }
      if(reports.includes('Promissory Note')){
        that.reportingToolPromissoryNotePresent = true;
      }
    })

    that.isDisclosureStatementPrintActive = false;
    printService.getPrint({printCode: 'LOAN_DISCLOSURE_STATEMENT'}).then(print => {
      that.isDisclosureStatementPrintActive = print.active;
    });

    that.isDisclosureStatementActive = () => {
      return that.selectedLoan && that.selectedLoan.status !== 'CLOSED' && (that.isDisclosureStatementPrintActive || that.reportingToolDisclosureStatementPresent);
    };

    that.disclosureStatement = async () => {
      if (that.reportingToolDisclosureStatementPresent) {
        if (!that.ongoingDisclosureStatementPrint) {
          that.ongoingDisclosureStatementPrint = true;
          await reportService.downloadFileReport({
            reportName: 'Disclosure Statement',
            parameters: {loanId: parseInt(that.loanId), userId: authentication.context.id}
          });
          that.ongoingDisclosureStatementPrint = false;
        }
      } else {
        await modalPrintPreviewService.showAsync({
          printDescription: {
            code: 'LOAN_DISCLOSURE_STATEMENT',
            parameters: {
              'PRODUCT_DEFINITION': that.selectedLoan.definitionId.toString()
            }
          },
          printProviderInput: {
            loanId: that.loanId
          }
        });
      }
    };

    that.isLoanReleasedToAccount = false;
    that.isLoanReleasedByGL = false;

    const fetchLoanReleaseOperation = async (loan) => {
      const releaseToAccountOperations = await operationService.fetchOperationsByCriteria({
        nxLoaderSkip: true,
        params: {
          productIds: [loan.id],
          dateFrom: loan.releaseDate,
          dateTo: loan.releaseDate,
          status: 'PROCESSED',
          attribute: ['RELEASE_METHOD'],
          ignorePagination: true,
          group: 'UPDATE_STATUS'
        }
      });

      const firstLoanActivation = releaseToAccountOperations.result
        .find(op => op.targetCode === 'UPDATE_STATUS_LOAN_ACTIVE');

      if (!firstLoanActivation) {
        return;
      }

      that.isLoanReleasedToAccount = firstLoanActivation.attributes['RELEASE_METHOD'] === 'CREDIT_TO_ACCOUNT';
      that.isLoanReleasedByGL = firstLoanActivation.attributes['RELEASE_METHOD'] === 'GL';
    };

    that.isReleaseToAccountStatementPrintActive = false;
    printService.getPrint({printCode: 'LOAN_RELEASE_TO_ACCOUNT_STATEMENT'}).then(print => {
      that.isReleaseToAccountStatementPrintActive = print.active;
    });

    that.isReleaseToAccountStatementActive = () => {
      return (that.isLoanReleasedToAccount || that.isLoanReleasedByGL) && that.isReleaseToAccountStatementPrintActive;
    };

    that.releaseToAccountStatement = () => {
      modalPrintPreviewService.showAsync({
        printDescription: {
          code: 'LOAN_RELEASE_TO_ACCOUNT_STATEMENT',
          parameters: {
            'PRODUCT_DEFINITION': this.selectedLoan.definitionId,
          }
        },
        printProviderInput: {
          loanId: that.loanId
        }
      });
    };

    that.isCheckPrintActive = false;
    modalPrintPreviewService.canReprint('LOAN_CHECK_PRINT', (active) => {
      that.isCheckPrintActive = active;
    });

    that.isPrintLoanCheckAvailable = () => that.selectedLoan?.checkPreparationId && that.isCheckPrintActive;

    that.printCheck = () => {
      modalPrintPreviewService.showAsync({
        printDescription: {
          code: 'LOAN_CHECK_PRINT',
          parameters: {}
        },
        printProviderInput: {
          loanId: that.loanId
        }
      });
    }

    that.showPaymentReceipt = async (transaction) => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'LOAN_PAYMENT_RECEIPT',
        printProviderInput: {
          operationId: transaction.id
        }
      });
    };

    that.isPrintReceiptAvailable = (transaction) => {
      if (that.isLoanPaymentReceiptActive && transaction.status === 'PROCESSED') {
        // if payment with late check
        if ('DEPOSIT_CUTOFF_CHECK' === transaction.operationGroup && 'LOAN_PAYMENT' === transaction.operationSubgroup
          // or regular payment
          || ['DEPOSIT_CASH', 'DEPOSIT_CHECK'].includes(transaction.operationGroup) && !transaction.attributes['LATE_CHECK']) {
          return true;
        } else if (transaction.operationGroup === 'TRANSFER_FUNDS') {
          return transaction.target && transaction.target.id == that.loanId;
        } else if (transaction.operationGroup === 'CREDIT_MEMO') {
          return transaction.target && transaction.target.id == that.loanId;
        }
      }

      return false;
    };

    that.isPaymentReceiptAvailableForEntry = (entry) => {
      if (!entry || !(entry.entryGroup === 'PAY_LOAN')) {
        return false;
      }
      if (this.isLoanPaymentReceiptActive && entry.status === 'PROCESSED' && entry.operationIds.length === 1) {
        return true;
      }
    };

    that.showPaymentReceiptForEntry = async (entry) => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'LOAN_PAYMENT_RECEIPT',
        printProviderInput: {
          operationId: entry.operationIds[0]
        }
      });
    };

    that.returnCheck = (transaction) => {
      const input = {'id': transaction.checkId};
      command.execute('ReturnLoanCheck', input).success(() => {
        customerCache.loans(customerId).refetch();
        customerCache.loanPDCs(customerId, that.selectedLoan.id).refetch();
        $route.reload();
      });
    };

    that.returnCheckForEntry = async (entry) => {
      const operation = await operationService.fetchOperationDetails(entry.operationIds[0]).toPromise();
      const input = {'id': operation.checkId};
      command.execute('ReturnLoanCheck', input).success(() => {
        customerCache.loans(customerId).refetch();
        customerCache.loanPDCs(customerId, that.selectedLoan.id).refetch();
        $route.reload();
      });
    };

    that.showGrantDateErrorNotification = () => {
      notification.show("Error", "Unable to perform release because date granted is in the future.");
    };

    that.releaseByCC = () => {
      if (that.isGrantDateValid()) {
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/cashier-check`);
      } else {
        that.showGrantDateErrorNotification();
      }
    };

    that.showLinkedDepositAccountErrorNotification = () => {
      notification.show(
        "Error",
        "Linked deposit account is enabled. Create and attach the linked deposit account first before proceeding to release."
      );
    }

    that.releaseBy = (method) => {
      if (!that.isGrantDateValid()) {
        that.showGrantDateErrorNotification();
      } else if (that.selectedLoan.loanType.withLinkedDepositAccount && !that.selectedLoan.linkedDepositAccount) {
        that.showLinkedDepositAccountErrorNotification();
      } else {
        switch (method) {
          case 'RESTRUCTURE':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/restructure`);
            break;
          case 'CONSOLIDATION':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/consolidation`);
            break;
          case 'CASH':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/cash`);
            break;
          case 'CREDIT_TO_ACCOUNT':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/credit-to-account`);
            break;
          case 'CHECK':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/check`);
            break;
          case 'GENERAL_LEDGER':
            $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/general-ledger`);
            break;
          default:
            console.error("Invalid loan release method");
            break;
        }
      }
    };

    that.deleteLoan = async () => {
      if (!that.selectedLoan.checkPreparationId) {
        const confirmed = await confirmation('Do you want to delete the selected loan?');
        await that.executeDeleteLoan(confirmed).toPromise();
      } else if (that.selectedLoan.checkPreparationId && that.approvedLoan?.checkPreparationStatus === 'FOR_RELEASE') {
        const {confirmed, remarks} = await that.deleteLoanConfirmationApi.show();
        await that.executeDeleteLoan(confirmed, remarks).toPromise();
      }
    };

    that.executeDeleteLoan = async (confirmed, remarks) => {
      if (!confirmed) {
        return;
      }

      const response = await command.execute('DeleteLoan', {
        productId: that.selectedLoan.id,
        checkCancellationRemarks: remarks
      }).toPromise();
      if (response.approvalRequired) {
        return;
      }
      that.refresh();
    }

    that.closeLoan = () =>
      confirmation('Do you want to close the selected loan?', () =>
        command.execute('CloseLoan', {productId: that.selectedLoan.id}).success(that.refresh));

    that.reopenLoan = () =>
      confirmation('Do you want to reopen selected loan?', () =>
        command.execute('ReopenLoan', {productId: that.selectedLoan.id}).success(that.refresh));

    that.recalculateAmortizationSchedule = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/recalculate-amortization-schedule`);

    that.refresh = () => {
      customerCache.loans(customerId).refetch();
      customerCache.creditLines(customerId).refetch();
      if (that.selectedLoan?.renewedLoanId) {
        productLockCache.withParam(that.selectedLoan.renewedLoanId).evict();
      }
      $route.reload();
    };

    that.isPartiallyPaid = amortization => {
      if (amortization.status === 'PAID') {
        return false;
      }

      // as earned loan after creation has shadow interests - the specific amount of interests paid depend
      // one the time loan was paid
      // for this case we set interest balance to 0, which results in a discrepancy between amortization balance and
      // amortization amount (the later includes also shadow interests)
      if (!that.selectedLoan.asEarnedInterestCalculation) {
        return amortization.amortizationAmount !== amortization.amortizationBalance;
      }

      const baseBalance = new BigNumber(amortization.amortizationAmount)
        .minus(amortization.interestAmount)
        .minus(amortization.amortizationBalance)
        .plus(amortization.interestBalance);

      if (!baseBalance.isNegative() && !baseBalance.isZero()) {
        return true;
      }

      return amortization.asEarnedInterestAmount !== amortization.asEarnedInterestBalance;
    };

    that.editInformation = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-information`);
    that.editLoanDocuments = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-documents`);
    that.editInterestAccrual = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-interest-accrual`);
    that.editCreditLossesAllowance = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-allowance-for-credit-losses`);
    that.editParamsAndCharges = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-params`);
    that.recreateDiscountChargesLedger = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/recreate-discount-charges`);
    that.editMetadata = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-metadata`);
    that.editRates = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-rates`);
    that.editLoanAmortizationWithoutPrincipal = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-amortization-without-principal`);
    that.editLoanAmortizationWithPrincipal = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-amortization-with-principal`);
    that.editCoMaker = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-co-maker`);

    that.waiveLoan = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/waive`);
    that.issuePdc = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/issue-pdc`);
    that.restructureLoan = () => $location.path(`/customer/${customerId}/loans/restructure/${that.selectedLoan.id}`);
    that.reconstructLoan = () => $location.path(`/customer/${customerId}/loans/reconstruct/${that.selectedLoan.id}`);

    that.renewLoan = () => {
      $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/renew`)
    };

    that.writeOffLoan = () => {
      $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/write-off`)
    };

    that.reprice = (interestSetting) => {
      if (interestSetting === 'REPRICEABLE') {
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/reprice`)
      } else {
        alert("The interest setting of the selected loan is set to 'Fixed'. It should be set to 'Repriceable' to use this operation.")
      }
    };

    that.isRepriceAllowed = () => {
      if (!that.selectedLoan) {
        return;
      }
      const unpaidAmortizations = that.selectedLoan.amortizationSchedule.list
        .filter(a => a.status !== 'PAID')
        .length;
      return that.selectedLoan
        && !that.hasAccruedLedgerEntries(that.selectedLoan)
        && ['ACTIVE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING'].includes(that.selectedLoan.status)
        && unpaidAmortizations > 0;
    };

    that.isLoanRestructureAllowed = () => {
      return that.selectedLoan && ['ACTIVE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING'].includes(that.selectedLoan.status);
    };

    that.isLoanReconstructionAllowed = () => {
      return that.selectedLoan && that.selectedLoan.loanType.allowReconstruction && ['ACTIVE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING'].includes(that.selectedLoan.status);
    };

    that.isWriteOffAllowed = () => {
      return that.selectedLoan && !['PAST_DUE_WRITE_OFF', 'CLOSED', 'PENDING', 'INACTIVE', 'ROPA'].includes(that.selectedLoan.status);
    };

    that.isTypeAssignableToGroup = () => {
      if (that.selectedLoan)
        return productService.availableForCustomerType(that.selectedLoan.loanType.productDefinition.customerTypeConstraints, 'GROUP');
    };
    that.assignLoanToGroup = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/assign-group`);

    that.keyValueDetails = _.memoize(currentTransaction => {
      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction, 'LOAN');

      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Line numbers', currentTransaction.attributes['AMORTIZATION_LINE_NUMBERS']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Interest amount', currentTransaction.attributes['INTEREST_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Principal amount', currentTransaction.attributes['PRINCIPAL_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Penalty amount', currentTransaction.attributes['PENALTY_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('PastDueInterest amount', currentTransaction.attributes['PAST_DUE_INTEREST_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('CBU amount', currentTransaction.attributes['CBU_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('TP amount', currentTransaction.attributes['TP_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('PF amount', currentTransaction.attributes['PF_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('UID line Number', currentTransaction.attributes['UID_LINE_NUMBER']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Official receipt', currentTransaction.attributes['OFFICIAL_RECEIPT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Temporary receipt', currentTransaction.attributes['TEMPORARY_RECEIPT']));
      baseValueDetails.push(transactionDetailsBuilder.buildCurrencyIfNotZero('Loan advance payment CBU', currentTransaction.attributes['LOAN_ADVANCE_PAYMENT_CBU']));
      baseValueDetails.push(transactionDetailsBuilder.buildCurrencyIfNotZero('Overpayment amount', currentTransaction.attributes['LOAN_OVERPAYMENT_AMOUNT']));
      baseValueDetails.push(transactionDetailsBuilder.buildCurrencyIfNotZero('Outstanding principal balance after', currentTransaction.attributes['OUTSTANDING_PRINCIPAL_BALANCE']));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    const fetchOperationsForProduct = async () => {
      if (!that.loanId) return;
      const result = await operationService.fetchOperationsByCriteria(
        {
          params: {
            productIds: [Number(that.loanId)],
            status: ['PROCESSED', 'PENDING'],
            excludeGroup: ['APPLY_PENALTY', 'APPLY_PAST_DUE_INTEREST']
          }
        }
      );
      that.productOperations = result.result;
    };

    const fetchRemadeProductNumbers = async () => {
      let remadeLoanIds = null;
      if(that.selectedLoan?.remadeFromLoanIds?.length > 0) {
        remadeLoanIds = that.selectedLoan.remadeFromLoanIds.join(',');
      } else if(that.selectedLoan?.renewedLoanId) {
        remadeLoanIds = that.selectedLoan.renewedLoanId;
      }

      if(remadeLoanIds) {
        const loans = await http.get(`/products/quick-search?ids=${remadeLoanIds}`).toPromise();
        that.remadeProductNumbers = loans.map(l => l.productNumber);
      }
    }

    that.deleteAllowed = () => {
      if (that.selectedLoan && that.productOperations) {
        return !['CLOSED', 'ROPA'].includes(that.selectedLoan.status)
            && (that.getReversiblePaymentOperations().length === 0 || that.deleteReleasedRemadeLoanAllowed())
            && (!that.selectedLoan.checkPreparationId || that.approvedLoan?.checkPreparationStatus === 'FOR_RELEASE')
            && !(that.isRenewedLoan() && that.selectedLoan.releaseDate);
      }
      return false;
    };

    that.releaseAllowed = () => {

      return that.selectedLoan?.status === 'INACTIVE'
        && !that.selectedLoan.groupLoanId
        && (
          !that.selectedLoan.checkPreparationId
          || that.approvedLoan?.checkPreparationStatus === 'FOR_RELEASE'
        );
    };

    that.deleteReleasedRemadeLoanAllowed = () => {
      if (that.selectedLoan && that.productOperations && (that.selectedLoan.metadata.restructured || that.selectedLoan.creationType === 'CONSOLIDATION')) {
        const paymentOperations = that.getReversiblePaymentOperations()
          .filter(o => !['RESTRUCTURE', 'CONSOLIDATION'].includes(o?.attributes['RELEASE_METHOD']))
          .filter(o => !that.isRemadeLoanFundsTransfer(o))
          .filter(o => !that.isExcessPayment(o));
        return !['CLOSED', 'ROPA'].includes(that.selectedLoan.status) && paymentOperations.length === 0 && that.selectedLoan.releaseDate;
      }
      return false;
    };

    that.getReversiblePaymentOperations = () => {
      return that.productOperations
        .filter(o =>
          'LOAN_PAYMENT' === o.operationSubgroup
          && o.operationGroup !== 'GL_TRANSFER'
          && !o.attributes['GL_TRANSFER_OPERATION_ID']);
    };

    that.onlyClosedLoans = () => {
      return that.loans && that.loans.filter(loan => loan.status !== 'CLOSED').length === 0;
    };

    that.printReleaseLoanByCashValidation = async (historyEntry) => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'LOAN_RELEASE_BY_CASH_VALIDATION',
        printProviderInput: {
          operationId: historyEntry.operationIds[0]
        }
      });
    };

    that.printReleaseGroupLoanByCashValidation = async (historyEntry) => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'LOAN_RELEASE_GROUP_BY_CASH_VALIDATION',
        printProviderInput: {
          operationIds: historyEntry.operationIds,
          commandId: historyEntry.commandId,
          groupLoanId: historyEntry.groupLoanId
        }
      });
    };

    const s = customerCache.profile(customerId, true, authentication.permissions['CST_CLOSED_CIF_READ']).toObservable()
      .combineLatest(customerCache.loans(customerId, false).toObservable(), (profile, loans) => {
        that.profile = profile;
        if (profile.customerType === 'GROUP') {
          // redirect to group loans
          $location.path(`/customer/${customerId}/group-loans`);
        }

        customerService.redirectWhenProfileIsInvalid(profile);
        if (profile.status === 'CLOSED') {
          return [];
        }

        if (that.commandAccessContext) {
          that.commandAccessContext.cache.clear();
          that.commandAccessContext(that.selectedLoan, profile);
        }

        return loans;
      })
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
          return loans.map(loan => {
            // add loanProduct to loan object
            const type = _.find(loanTypes, {id: loan.typeId});
            return Object.assign(loan, {
              loanType: type
            });
          })
        }
      ).combineLatest(customFieldCategoryDetailsCache.withParam({
          group: 'LOAN',
          asForest: false
        }).toObservable(), (loans, categoryDetails) =>
          loans.map(loan => {
            const matchingCategories = (loan.categoryIds || [])
              .map(id => _.find(categoryDetails, {categoryId: id}))
              .filter(category => category);
            return {...loan, customCategories: matchingCategories};
          })
      ).combineLatest(branchService.toObservable(), (loans, branches) => {
          loans.map(loan => {
            if (loan.id == that.loanId) {
              let loanBranch = _.find(branches, {id: loan.branchId});
              that.branchSystemDate = systemDateService.getSystemDate(loanBranch);
            }
          });
          return loans;
        }
      ).combineLatest(branchService.toObservable(), (loans, branches) =>
        loans.map(loan => Object.assign(loan, {branch: _.find(branches, {id: loan.branchId})}))
      )
      .combineLatest(userCache.withParam().toObservable(), (loans, users) =>
        loans.map(loan => Object.assign(loan, {user: _.find(users, {id: loan.userId})}))
      ).combineLatest(customerCache.customerProductFees(customerId, 'LOAN').toObservable(), (loans, customerLoanFees) => {
        _.forEach(loans, (loan) => {
          loan.fees = [];
          _.forEach(customerLoanFees, (fee) => {
            if (fee.productId === loan.id) {
              loan.fees.push(fee);
            }
          });
        });
        return loans;
      }).combineLatest(feeDefinitionsCache.toObservable(), (loans, feeDefinitions) => {
        _.forEach(loans, (loan) => {
          _.forEach(loan.fees, (fee) => {
            const feeDef = _.find(feeDefinitions, {id: fee.feeDefinitionId});
            fee.feeName = feeDef.feeName;
          })
        });
        return loans;
      }).subscribe(loans => {
          // select first account when none selected
          if (!that.loanId && loans.length > 0) {
            $location.path(`/customer/${customerId}/loans/${loans[0].id}`);
          }

          if (that.loanId) {
            const loan = _.find(loans, (a) => String(a.id) === that.loanId);
            if (loan) {
              assignContractualSavingsAccounts(loan);
              assignLinkedDepositAccount(loan);
              assignCoMakers(loan);
              fetchLoanReleaseOperation(loan);
              fetchLoanPDCs(loan);
              setPenaltyConfig(loan);
              fetchLetters(loan);
              fetchApprovedLoanDetails(loan);
              fetchRemadeToLoan(loan)

              // mark first unpaid transaction
              for (let a of that.selectedLoan.amortizationSchedule.list) {
                if (a.status !== 'PAID') {
                  a.currentAmortization = true;
                  break;
                }
              }
            } else {
              $location.path(`/customer/${customerId}/loans`);
            }
          }

          that.loans = loans;

          if (that.showClosed) {
            that.visibleLoans = loans;
          } else {
            that.visibleLoans = loans.filter(loan => loan.status !== 'CLOSED');

            if (that.selectedLoan && that.selectedLoan.status === 'CLOSED' && that.selectLoan && that.visibleLoans[0]) {
              that.selectLoan(that.visibleLoans[0]);
            } else if (that.selectedLoan && that.selectedLoan.status === 'CLOSED' && that.onlyClosedLoans()) {
              that.showClosed = true;
              that.toggleClosedLoans();
            }
          }

          fetchOperationsForProduct();
          fetchRemadeProductNumbers();

          if (that.selectedLoan && that.selectedLoan.amortizationSchedule.list) {
            that.loanTableData = that.prepareAmortizationTable(that.selectedLoan.amortizationSchedule);
            that.amortizationTable.reload();
          }

          return loans;
        }
      );

    const subscription = new Subscription();
    subscription.add(s);

    that.formatProductAndExtraNumber = (item) =>
      (item.extraNumber === item.productNumber || !item.extraNumber) ? item.productNumber :
        `${item.productNumber} (${item.extraNumber})`;

    that.isRestructuredLoan = () => {
      return that.selectedLoan && that.selectedLoan.creationType === 'RESTRUCTURED';
    };

    that.isConsolidatedLoan = () => {
      return that.selectedLoan?.creationType === 'CONSOLIDATION';
    };

    that.isRenewedLoan = () => {
      return that.selectedLoan && that.selectedLoan.renewedLoanId && renewedLoanCreationTypes.includes(that.selectedLoan.creationType);
    };

    that.isRemadeLoan = () => {
      return that.isRestructuredLoan() || that.isConsolidatedLoan();
    }

    that.isRemadeLoanFundsTransfer = (operation) => {
      return operation?.source?.id === that.selectedLoan.id
        && that.selectedLoan?.remadeFromLoanIds?.includes(operation.target?.id)
        && operation.operationGroup === 'TRANSFER_FUNDS'
        && operation.operationSubgroup === 'LOAN_PAYMENT'
        && ['RESTRUCTURED', 'CONSOLIDATION'].includes(that.selectedLoan.creationType);
    };

    that.isExcessPayment = (operation) => {
      return operation?.source?.id === that.selectedLoan.id
        && operation.target.id === that.selectedLoan.id
        && operation.operationGroup === 'TRANSFER_FUNDS'
        && operation.operationSubgroup === 'LOAN_PAYMENT';
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };

    that.isRenewalAllowed = () => {
      return that.selectedLoan && that.selectedLoan.loanType.allowRenewal && !['INACTIVE', 'PENDING', 'CLOSED', 'ROPA'].includes(that.selectedLoan.status);
    };

    that.showAccrualHistory = () => {
      return that.selectedLoan
        && that.selectedLoan.loanType
        && that.selectedLoan.loanType.allowAccrual
        && !that.selectedLoan.asEarnedInterestCalculation;
    };

    that.hasLetters = () => {
      return that.loanLetters && that.loanLetters.length > 0;
    }

    that.revertTransferToRopa = async (transaction) => {
      const proceed = await confirmationTemplate({
        question: 'You are about to revert the ROPA loan back to its previous loan status? <br> This will action will also reverse the GL entries. <br><br> Proceed?'
      });

      if (!proceed) {
        return;
      }

      const response = await command.execute('TransferLoanToRopaRevert', {productId: that.selectedLoan.id, commandId: transaction.commandId}).toPromise();
      if (!response?.approvalRequired) {
        notification.show('Success', 'Successfully reverted ROPA loan');
        customerCache.loans(customerId).refetch();
        $route.reload();
      }
    };

    that.deleteLoanConfirmationApi = null;
    that.onDeleteLoanConfirmationReady = (api) => that.deleteLoanConfirmationApi = api;
  }
});
