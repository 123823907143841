import nxModule from 'nxModule';

import templateUrl from './official-receipt-booklet-assign.template.html';
import {IFormController, ILocationService} from 'angular';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {HttpService} from 'shared/utils/httpService';
import {
  OfficialReceiptBooklet
} from 'components/administration/official-receipt-booklet/list/official-receipt-booklet-list.component';
import {PageResult} from "tools/HttpTypes";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

class OfficialReceiptBookletAssign {
  protected form!: IFormController;
  protected users!: UserDetails[];
  protected selectedUser?: UserDetails;
  private branchId!: number;
  private bookletId!: number;
  private booklet?: OfficialReceiptBooklet;

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private userCache: UserCache,
              private http: HttpService) {
  }

  async $onInit(): Promise<void> {
    this.branchId = Number(this.$route.current.params['branchId']);
    this.bookletId = Number(this.$route.current.params['id']);

    const [users, booklet] = await Promise.all([
      this.userCache.withParam(true).toPromise(),
      this.getBooklet()
    ]);

    this.booklet = booklet;
    this.users = users.sort((u1, u2) => u1.fullName.localeCompare(u2.fullName));
    if (booklet) {
      this.selectedUser = this.users.find(u => u.id === booklet.userId);
    }
  }

  async getBooklet(): Promise<OfficialReceiptBooklet | undefined> {
    const booklets: PageResult<OfficialReceiptBooklet> = await this.http.post<PageResult<OfficialReceiptBooklet>>(
      '/management/official-receipt-booklet',
      {id: this.bookletId})
      .toPromise();

    return booklets.result.length === 1 ? booklets.result[0] : undefined;
  }

  async assign(): Promise<void> {
    const input = {
      bookletId: this.bookletId,
      userId: this.selectedUser?.id
    };

    const confirmed = await this.confirmationTemplate({
      question: 'Assign Invoice',
      details: [
        {label: 'User', description: this.selectedUser?.effectiveName || 'No user selected'}
      ]
    });

    if (confirmed) {
      const response = await this.command.execute('AssignOfficialReceiptBooklet', input).toPromise();
      if (!response.approvalRequired) {
        this.redirectBack();
      }
    }
  }

  redirectBack(): void {
    this.$location.path(`/admin/invoice-branches/${this.branchId}`);
  }
}

nxModule.component('officialReceiptBookletAssign', {
  templateUrl: templateUrl,
  controller: OfficialReceiptBookletAssign
});
